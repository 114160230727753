import styled from 'styled-components'

import { mobile } from '../../mixins'
import { Color } from '../../theme'
import { CalloutRegular, heading2Style } from '../../typography'

export const Outer = styled.div`
  width: 100%;
  max-width: 640px;
  display: flex;
  flex-direction: column;
`

export const Title = styled.h2`
  ${heading2Style({ noResponsive: true })}
  text-align: center;
`

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  ${mobile(`
    margin-top: 32px;
  `)}

  > *:nth-child(n+2) {
    margin-top: 12px;
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;

  border: 1px solid ${Color.GRAY_3};
  border-radius: 16px;
  cursor: pointer;

  ${mobile(`
    padding: 16px;
  `)}
`

export const CardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 4px;

  svg {
    color: ${Color.GRAY_6};
    flex-shrink: 0;
  }
`

export const Description = styled(CalloutRegular)`
  padding: 24px 35px 8px 0;

  ${mobile(`
    padding: 16px 0 0 0;
  `)}
`
