export enum SignUpFormProperty {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  COUNTRY = 'country',
  PHONE = 'phone',
  COMPANY_NAME = 'companyName',
  HOW_HEARD = 'howHeard',
}

export type SignUpFormProps = Record<SignUpFormProperty, string> &
  Partial<{
    isHighDelegator: boolean
    isTeam: boolean
    isUnsure: boolean
  }>
