import styled from 'styled-components'

import { Color } from '../../components/theme'

import type { ButtonProps } from './shared'
import { bigButtonStyle } from './shared'

const BigButtonText = styled.button<ButtonProps>`
  ${bigButtonStyle}

  background: transparent;
  color: ${({ disabled }) => (disabled ? Color.GRAY_6 : Color.PURPLE_5)};
  box-shadow: none;

  p {
    color: ${({ disabled }) => (disabled ? Color.GRAY_6 : Color.PURPLE_5)};
  }
`

export default BigButtonText
