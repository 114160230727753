import styled from 'styled-components'

import { maxScreenWidth } from '../../components/mixins'

export const Outer = styled.div`
  width: 1000px;
  height: 650px;
  margin: auto;

  ${maxScreenWidth(1000)(`
    width: 650px;
  `)}

  ${maxScreenWidth(650)(`
    width: 100%;
  `)}
`
