import fscreen from 'fscreen'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import type { Strapi_SignUp_VideoPlayerFragment } from '../../../../../graphql-types'
import useIsMobileView from '../../../../hooks/useIsMobileView'
import Modal from '../../../../system/Modal'
import CloseIcon from '../../../../system/icons/close-l.inline.svg'
import MobileCloseIcon from '../../../../system/icons/close-s.inline.svg'
import { Heading3 } from '../../../typography'
import { getStrapiMediaSourceUrl } from '../helpers'

import {
  CloseButton,
  Subtitle,
  TitleContainer,
  VideoContainer,
  VideoOuter,
  VideoWrapper,
} from './styled'

const VideoPlayer = ({
  posterTitle,
  posterImage,
  video,
}: Strapi_SignUp_VideoPlayerFragment): JSX.Element => {
  const vidRef = useRef<HTMLVideoElement | null>(null)
  const [duration, setDuration] = useState<number>()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const isMobileView = useIsMobileView()

  // get video duration
  const onLoadedMetadata = (): void => {
    if (vidRef.current?.duration) {
      setDuration(Math.ceil(vidRef.current?.duration / 60))
    }
  }

  const openVideoPlayer = useCallback(async () => {
    setIsModalOpen(true)

    // if mobile (and can open fullscreen), open in fullscreen
    if (
      isMobileView &&
      fscreen.fullscreenEnabled &&
      vidRef.current &&
      vidRef.current?.requestFullscreen
    ) {
      await vidRef.current.requestFullscreen()
    }

    // start playing automatically
    await vidRef.current?.play()
  }, [isMobileView])

  const closeModal = useCallback(() => {
    // pause video when modal closed
    vidRef.current?.pause()

    setIsModalOpen(false)
  }, [setIsModalOpen])

  // close video player when fullscreen mode is closed
  useEffect(() => {
    const fullscreenChangeHandler = (): void => {
      if (!fscreen.fullscreenElement) {
        closeModal()
      }
    }

    const fullscreenErrorHandler = (): void => {
      console.error('Failed to open fullscreen')
    }

    fscreen.addEventListener('fullscreenchange', fullscreenChangeHandler)
    fscreen.addEventListener('fullscreenerror', fullscreenErrorHandler)

    return () => {
      fscreen.removeEventListener('fullscreenchange', fullscreenChangeHandler)
      fscreen.removeEventListener('fullscreenerror', fullscreenErrorHandler)
    }
  }, [closeModal])

  // handle prod & local CMS instances
  const posterImageSrc = getStrapiMediaSourceUrl(posterImage?.url)
  const videoSrc = getStrapiMediaSourceUrl(video?.url)

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        prerenderContent
        hideCloseButton
      >
        <VideoOuter>
          <CloseButton onClick={closeModal}>
            {isMobileView ? <MobileCloseIcon /> : <CloseIcon />}
          </CloseButton>
          <VideoContainer>
            <video
              ref={vidRef}
              src={videoSrc}
              onLoadedMetadata={onLoadedMetadata}
              controls
            />
          </VideoContainer>
        </VideoOuter>
      </Modal>
      <VideoWrapper>
        <TitleContainer>
          <Heading3>{posterTitle}</Heading3>
          {duration && (
            <Subtitle>{`${duration} minute${
              duration > 1 ? 's' : ''
            }`}</Subtitle>
          )}
        </TitleContainer>
        <img src={posterImageSrc} onClick={openVideoPlayer} />
      </VideoWrapper>
    </>
  )
}

export default VideoPlayer
