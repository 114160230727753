/* eslint-disable camelcase */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import useScript from 'react-script-hook'

import signup from '../../components/join-forms/signup'
import { trackEvent } from '../../components/utils'
import getSHA256Hash from '../../utils/getSHA256Hash'

import { Outer } from './styled'

type MessageEventType = MessageEvent<{ event: string }>

type Props = {
  calendlyLink: string
  leadRecordId?: string
  name?: string
  email?: string
  phone?: string
  countryValue?: 'United States/Canada' | 'France/Central Europe' | 'Other'
}

// https://help.calendly.com/hc/en-us/articles/223147027?tab=advanced#6
const isCalendlyEvent = (e: MessageEventType): boolean =>
  Boolean(
    e.origin === 'https://calendly.com' &&
      e.data.event &&
      e.data.event.indexOf('calendly.') === 0,
  )

const CalendlyScheduler = ({
  calendlyLink,
  leadRecordId,
  name,
  email,
  phone,
  countryValue,
}: Props): JSX.Element => {
  const calendlyContainer = useRef<HTMLDivElement>(null)
  const [widgetRendered, setWidgetRendered] = useState(false)
  const [loadingCalendly, errorLoadingCalendly] = useScript({
    src: 'https://proxy.withdouble.com/assets.calendly.com/assets/external/widget.js',
  })

  const calendlyOk = !loadingCalendly && !errorLoadingCalendly

  const onCalendlyMessage = useCallback(
    async (e: MessageEventType) => {
      if (isCalendlyEvent(e)) {
        const {
          data: { event },
        } = e

        if (event === 'calendly.date_and_time_selected') {
          trackEvent('signup_calendly_select_call_time')
        } else if (event === 'calendly.event_scheduled') {
          trackEvent('signup_calendly_confirm_call_scheduling', {
            ...(name ? { name, sha256_name: await getSHA256Hash(name) } : {}),
            ...(email
              ? { email, sha256_email: await getSHA256Hash(email) }
              : {}),
            ...(phone
              ? { phone, sha256_phone: await getSHA256Hash(phone) }
              : {}),
          })

          if (leadRecordId) {
            signup({
              leadRecordId,
              introCallScheduled: true,
            }).catch((err) => {
              console.error('Error updating lead - introCallScheduled', err)
            })
          }
        }
      }
    },
    [email, leadRecordId, name, phone],
  )

  // Listen to Calendly widget messages
  useEffect(() => {
    window.addEventListener('message', onCalendlyMessage)
    return () => window.removeEventListener('message', onCalendlyMessage)
  }, [onCalendlyMessage])

  useEffect(() => {
    if (
      // @ts-ignore
      !window.Calendly ||
      !calendlyContainer.current ||
      !calendlyOk ||
      widgetRendered
    ) {
      return
    }

    // Initialize Calendly widget
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    window.Calendly.initInlineWidget({
      url: countryValue
        ? `${calendlyLink}?a1=${encodeURIComponent(countryValue)}&autofill=true`
        : calendlyLink,
      parentElement: calendlyContainer.current,
      prefill: {
        name: name || '',
        email: email || '',
      },
      utm: {},
    })
    setWidgetRendered(true)
  }, [calendlyLink, calendlyOk, countryValue, email, name, widgetRendered])

  return (
    <Outer
      ref={calendlyContainer}
      className={'calendly-inline-widget'}
      data-auto-load={false}
    />
  )
}

export default CalendlyScheduler
