import React, { useMemo } from 'react'

import useIsMobileView from '../../hooks/useIsMobileView'
import useWindowWidth from '../../hooks/useWindowWidth'
import CheckedCircle from '../../system/icons/checked-circle.inline.svg'
import Circle from '../../system/icons/circle.inline.svg'
import HalfCircle from '../../system/icons/half-circle.inline.svg'
import { StepStatus } from '../modules/strapi/types'
import { Color } from '../theme'

import {
  DESKTOP_LABEL_WIDTH,
  IconAndSegmentOuter,
  Item,
  Label,
  Outer,
  Segment,
} from './styled'

interface Props {
  currentStepIndex: number
  labels: string[]
  currentStepStatus?: StepStatus
}

const StepsIndicator = ({
  labels,
  currentStepIndex,
  currentStepStatus,
}: Props): JSX.Element => {
  const windowWidth = useWindowWidth()
  const isMobileView = useIsMobileView()

  const collapseStepLabels = useMemo(
    () =>
      isMobileView ? true : labels.length * DESKTOP_LABEL_WIDTH > windowWidth,
    [isMobileView, labels.length, windowWidth],
  )

  return (
    <Outer collapseStepLabels={collapseStepLabels}>
      {labels.map((label, index) => {
        const isFirst = index === 0
        const isLast = index === labels.length - 1
        const isPrevChecked = index < currentStepIndex + 1
        const isChecked = index < currentStepIndex
        const isActiveStep = index === currentStepIndex
        const isHalfStep = isActiveStep && currentStepStatus === StepStatus.HALF
        const isFullStep = isActiveStep && currentStepStatus === StepStatus.FULL
        const hideLabel =
          !(isActiveStep || (isChecked && isLast)) && collapseStepLabels

        return (
          <Item
            key={`${index}-${label}`}
            collapseStepLabels={collapseStepLabels}
          >
            <IconAndSegmentOuter>
              <Segment highlighted={isPrevChecked} hide={isFirst} />
              {isChecked || isFullStep ? (
                <CheckedCircle css={{ color: Color.PURPLE_5, zIndex: 1 }} />
              ) : isHalfStep ? (
                <HalfCircle css={{ color: Color.PURPLE_5, zIndex: 1 }} />
              ) : isActiveStep ? (
                <Circle css={{ color: Color.PURPLE_5, zIndex: 1 }} />
              ) : (
                <Circle css={{ color: Color.GRAY_3, zIndex: 1 }} />
              )}
              <Segment highlighted={isChecked} hide={isLast} />
            </IconAndSegmentOuter>
            {!hideLabel && (
              <Label highlighted={isChecked || isActiveStep}>{label}</Label>
            )}
          </Item>
        )
      })}
    </Outer>
  )
}

export default StepsIndicator
