import { navigate } from 'gatsby'
import React, { useCallback, useMemo, useState } from 'react'

import type { Maybe } from '../../../../../graphql-types'
import Modal from '../../../../system/Modal'
import SmallButtonPrimary from '../../../../system/buttons/SmallButtonPrimary'
import CalendlyWrapper from '../CalendlyWrapper'
import { ButtonType } from '../types'

import { BorderButton, PrimaryButton, SecondaryButton } from './styled'

type Props = {
  type: ButtonType
  label: string
  navigateTo?: string
  calendlyLink?: Maybe<string>
  action?: () => void | Promise<void> | null
}

const Button = ({
  type,
  label,
  navigateTo,
  calendlyLink,
  action,
}: Props): JSX.Element => {
  const [calendlyIsOpen, setCalendlyIsOpen] = useState(false)
  const openModal = (): void => {
    setCalendlyIsOpen(true)
  }
  const closeModal = (): void => {
    setCalendlyIsOpen(false)
  }

  const buttonClicked = useCallback(async () => {
    // run custom action
    if (action) {
      await action()
    }
    // open calendly component
    if (calendlyLink) {
      openModal()
    }
    // navigate to endpoint
    else if (navigateTo) {
      Promise.resolve(navigate(navigateTo)).catch(console.error)
    }
  }, [action, calendlyLink, navigateTo])

  const ButtonElement = useMemo(() => {
    switch (type) {
      // TODO: SECONDARY_SMALL & BORDER_SMALL are not currently implemented and are not used in Strapi
      case ButtonType.PRIMARY:
        return PrimaryButton
      case ButtonType.PRIMARY_SMALL:
        return SmallButtonPrimary
      case ButtonType.SECONDARY:
      case ButtonType.SECONDARY_SMALL:
        return SecondaryButton
      case ButtonType.BORDER:
      case ButtonType.BORDER_SMALL:
        return BorderButton
    }
  }, [type])

  return (
    <>
      {calendlyLink && (
        <Modal isOpen={calendlyIsOpen} onClose={closeModal} prerenderContent>
          <CalendlyWrapper calendlyLink={calendlyLink} />
        </Modal>
      )}
      <ButtonElement onClick={buttonClicked}>{label}</ButtonElement>
    </>
  )
}

export default Button
