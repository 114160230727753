import { graphql, useStaticQuery } from 'gatsby'
import compact from 'lodash/compact'
import uniq from 'lodash/uniq'
import React, { useMemo } from 'react'

import type { SignUpStepsQuery } from '../../../graphql-types'
import StepsIndicator from '../StepsIndicator'
import type { StepStatus } from '../modules/strapi/types'

const query = graphql`
  query SignUpSteps {
    steps: allStrapiSignUpPage(
      filter: { locale: { eq: "en" }, step: { id: { ne: null } } }
      sort: { fields: index }
    ) {
      nodes {
        ...SignUpStep
      }
    }
  }
`

type Props = {
  currentPageIndex: number
}

const StepsIndicatorWrapper = ({ currentPageIndex }: Props): JSX.Element => {
  const data = useStaticQuery<SignUpStepsQuery>(query)
  const steps = data.steps.nodes
  const currentStep = useMemo(
    () => steps.find((step) => step.index === currentPageIndex),
    [currentPageIndex, steps],
  )

  // filter out repeat steps
  const labels = useMemo(
    () => uniq(compact(steps.map((step) => step.stepData?.label))),
    [steps],
  )

  // set step index based on matching label (this handles the case of multiple pages within one step)
  const adjustedStepIndex = labels.findIndex(
    (label: string) => label === steps[currentPageIndex]?.stepData?.label,
  )

  return (
    <StepsIndicator
      labels={labels}
      currentStepIndex={
        adjustedStepIndex > -1 ? adjustedStepIndex : currentPageIndex
      }
      currentStepStatus={currentStep?.stepData?.stepStatus as StepStatus}
    />
  )
}

export default StepsIndicatorWrapper
