import styled from 'styled-components'

import { mobile } from '../../../mixins'

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 20px;

  ${mobile(`
    flex-direction: column-reverse;
    row-gap: 12px;
    padding: 0 24px;
  `)}
`
