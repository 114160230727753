import styled, { css } from 'styled-components'

import { Color } from '../../components/theme'
import { calloutRegularStyle } from '../../components/typography'

const SmallInput = styled.input<{ $isInvalid?: boolean }>`
  ${calloutRegularStyle({})}
  height: 36px;
  padding: 0 12px !important;

  border: 1px solid ${Color.GRAY_2};
  border-radius: 10px;
  background: transparent;

  &::placeholder {
    color: ${Color.GRAY_5};
  }

  &:focus {
    border-color: ${Color.PURPLE_4};
    box-shadow: 0 0 0 2px ${Color.PURPLE_2};
  }

  ${({ $isInvalid }) =>
    $isInvalid &&
    css`
      border-color: ${Color.FLAMINGO_5};
      box-shadow: 0 0 0 2px ${Color.FLAMINGO_3};
    `}
`

export default SmallInput
