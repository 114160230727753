import React from 'react'

import {
  Outer,
  BoldTitle,
  RemainingTitle,
  Title,
  Icon,
  CheckIcon,
} from './styled'

type Props = {
  icon: string
  boldTitle?: string
  remainingTitle: string
  onClick?: () => void
  isSelected: boolean
}

const OptionButton = ({
  icon,
  boldTitle,
  remainingTitle,
  onClick,
  isSelected,
}: Props): JSX.Element => (
  <Outer onClick={onClick} $isSelected={isSelected}>
    <Icon src={icon} alt={'logo'} $isSelected={isSelected} />
    <Title>
      {boldTitle && <BoldTitle>{boldTitle}&nbsp;</BoldTitle>}
      <RemainingTitle>{remainingTitle}</RemainingTitle>
    </Title>
    {isSelected && <CheckIcon />}
  </Outer>
)

export default OptionButton
