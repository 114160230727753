import type { ComponentProps } from 'react'
import React, { useEffect, useRef } from 'react'

import CloseIcon from '../../system/icons/close-l.inline.svg'

import { CloseIconContainer, Outer } from './styled'

type Props = Pick<ComponentProps<'div'>, 'children'> & {
  isOpen: boolean
  prerenderContent?: boolean
  onClose?: () => void
  hideCloseButton?: boolean
}

const Modal = ({
  isOpen,
  prerenderContent,
  onClose,
  children,
  hideCloseButton,
}: Props): JSX.Element | null => {
  // disable scrolling behind the modal
  const originalOverflow = useRef<string | null>(null)

  useEffect(() => {
    if (typeof window !== 'undefined' && window.document) {
      // save original value on the first render
      if (originalOverflow.current === null) {
        originalOverflow.current = window.document.body.style.overflow
      }
      // modify it subsequently
      window.document.body.style.overflow = isOpen
        ? 'hidden'
        : originalOverflow.current
    }
  }, [isOpen])

  if (!prerenderContent && !isOpen) {
    return null
  }

  return (
    <Outer onClick={onClose} $isOpen={isOpen}>
      {!hideCloseButton && (
        <CloseIconContainer onClick={onClose}>
          <CloseIcon />
        </CloseIconContainer>
      )}
      <div onClick={(e) => e.stopPropagation()}>{children}</div>
    </Outer>
  )
}

export default Modal
