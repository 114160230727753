import { useEffect, useState } from 'react'

import type { Maybe, SignUpPageFragment } from '../../../../graphql-types'
import { setTypeformParams } from '../../../components/modules/CustomModule/Typeform'
import { SIGNUP_LEAD_STATE_KEY } from '../../../constants'
import useSessionState from '../../../hooks/useSessionState'
import type { Lead } from '../../../pages/joined'
import { DISCOUNT_CODE_KEY, LEAD_ID_KEY, SIGN_UP_PREFIX } from '../constants'

type Props = {
  signUpPage: Maybe<SignUpPageFragment> | undefined
  pageContext: { slug?: string }
  firstPageSlug?: Maybe<string>
  navigate: (path: string) => Promise<void>
}

type ReturnProps = {
  searchParamsLoaded: boolean
  leadState?: { value?: Partial<Lead> }
  setLeadState: (values: Partial<Lead>, overwrite: boolean) => void
}

/**
 * Hook for loading session storage and URL search params for Sign Up pages
 */
const useSignUpParams = ({
  signUpPage,
  pageContext,
  firstPageSlug,
  navigate,
}: Props): ReturnProps => {
  const [searchParamsLoaded, setSearchParamsLoaded] = useState<boolean>(false)
  const [leadState, setLeadState] = useSessionState<Partial<Lead>>(
    SIGNUP_LEAD_STATE_KEY,
  )

  // look for leadId & discount code search params on load
  useEffect(() => {
    try {
      if (window.location.search) {
        const url = new URL(window.location.href)
        const leadId = url.searchParams.get(LEAD_ID_KEY)
        const discountCode = url.searchParams.get(DISCOUNT_CODE_KEY)
        const newLeadState: Partial<Lead> = {}

        if (leadId) {
          setTypeformParams({
            // eslint-disable-next-line camelcase
            lead_record_id: leadId,
          })

          newLeadState.leadRecordId = leadId

          // remove leadId search param
          url.searchParams.delete(LEAD_ID_KEY)
          window.history.pushState({}, '', url.toString())
        }

        if (discountCode) {
          newLeadState.discountCode = discountCode
        }

        if (Object.keys(newLeadState).length > 0) {
          setLeadState(newLeadState, false)
        }
      }
    } catch (error) {
      console.error(`Failed to read ${LEAD_ID_KEY} from URL query`, error)
    } finally {
      setSearchParamsLoaded(true)
    }
  }, [setLeadState])

  // after search params have been loaded, redirect to first page in SignUp series if missing lead info
  useEffect(() => {
    if (searchParamsLoaded) {
      if (
        firstPageSlug !== pageContext.slug &&
        !!signUpPage?.redirectOnMissingInfo &&
        !leadState?.value &&
        !window.location?.hash?.includes('debug')
      ) {
        navigate(
          firstPageSlug ? `${SIGN_UP_PREFIX}/${firstPageSlug}` : '/',
        ).catch(console.error)
      }
    }
  }, [
    firstPageSlug,
    leadState?.value,
    navigate,
    pageContext.slug,
    searchParamsLoaded,
    signUpPage?.redirectOnMissingInfo,
  ])

  return {
    searchParamsLoaded,
    leadState,
    setLeadState,
  }
}

export default useSignUpParams
