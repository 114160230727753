import styled, { css } from 'styled-components'

import { Outer as StepsIndicator } from '../../components/StepsIndicator/styled'
import { mobile } from '../../components/mixins'
import { Hero } from '../../components/modules'
import UnstyledLoader from '../../system/Loader'

export const Outer = styled.div<{ footerFixed?: boolean }>`
  min-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ footerFixed }) => css`
    padding-bottom: ${footerFixed ? '104px' : '0'};

    ${mobile(`
      margin-top: 0px;
      padding-bottom: ${footerFixed ? '65px' : '0'};
    `)}
  `}

  ${StepsIndicator} {
    margin-top: 44px;

    ${mobile(`
      margin-top: 0px;
    `)}
  }
`

export const Loader = styled(UnstyledLoader)`
  height: 500px;

  ${mobile(`
    height: 400px;
  `)}
`

export const Inner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 54px;

  ${mobile(`
    padding-bottom: 48px;
  `)}
`

export const TitleImage = styled.img<{ hasStepIndicator?: boolean }>`
  ${({ hasStepIndicator }) => css`
    width: 100%;
    max-width: 375px;
    margin-top: ${hasStepIndicator ? '52px' : '72px'};

    ${mobile(`
      max-width: 320px;
      margin-top: ${hasStepIndicator ? '-22px' : '52px'};
    `)}
  `}
`

export const StyledHero = styled(Hero)<{ hasTitleImage?: boolean }>`
  ${({ hasTitleImage }) => css`
    margin-top: ${hasTitleImage ? '32px' : '72px'};
    padding: 0 32px;

    ${mobile(`
      margin-top: ${hasTitleImage ? '0' : '32px'};
      padding: 0 24px;
    `)}
  `}
`
