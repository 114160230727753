import styled from 'styled-components'

import { Color } from '../../../../theme'
import { bodyMediumStyle, bodySemiboldStyle } from '../../../../typography'

import _CheckIcon from './Checkmark.inline.svg'

export const Outer = styled.div<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  padding: 24px;
  gap: 20px;

  max-width: 800px;
  width: 100%;
  height: 77px;

  border: 1px solid
    ${({ $isSelected }) => ($isSelected ? Color.PURPLE_5 : Color.GRAY_3)};
  border-radius: 16px;

  // Only hover if not selected
  ${({ $isSelected }) =>
    !$isSelected &&
    `
  &:hover {
    cursor: pointer;
    border: 1px solid ${Color.GRAY_1};
    background-color: ${Color.GRAY_1};
  }
  `}
`

export const Icon = styled.img<{ $isSelected: boolean }>`
  width: 24px;
  height: 24px;

  /* 💩 Hack to make the svg fill color work with Strapi */
  ${({ $isSelected }) =>
    $isSelected &&
    `
  filter: invert(45%) sepia(100%) saturate(5851%) hue-rotate(241deg) brightness(102%) contrast(104%);
  `}
`

// @ts-ignore - svg components not recognized as valid react components
export const CheckIcon = styled(_CheckIcon)`
  width: 24px;
  height: 24px;
  margin-left: auto;
  margin-right: 0;
  color: ${Color.PURPLE_5};
`

export const Title = styled.div`
  display: flex;
  flex-flow: row wrap;
`

export const BoldTitle = styled.h2`
  ${bodySemiboldStyle}
`

export const RemainingTitle = styled.h2`
  ${bodyMediumStyle}
`
