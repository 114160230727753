/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Link as NavLink } from 'gatsby'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { CAREERS_PATH, SIGNUP_LEAD_STATE_KEY } from '../../../../constants'
import countries from '../../../../countries'
import useCountryFromNavigator from '../../../../hooks/useCountryFromNavigator'
import useSessionState from '../../../../hooks/useSessionState'
import type { Lead } from '../../../../pages/joined'
import { useSignUpPageContext } from '../../../../templates/sign-up-page'
import { SignUpFormProperty } from '../../../../templates/sign-up-page/types'
import { CalloutRegular } from '../../../typography'
import { validateEmail, validatePhoneNumber } from '../../../utils'

import {
  AssistantRedirect,
  EmailInput,
  Inner,
  Input,
  NameInputContainer,
  Outer,
  SectionHeader,
  SelectInputWithSeparator,
} from './styled'

const HEARD_ABOUT_DOUBLE_OPTIONS = [
  'Google',
  'Referral',
  'Social Media',
  'Podcast',
  'Word of Mouth',
  'Other',
]

const SignUpForm = (): JSX.Element => {
  const firstNameInputRef = useRef<HTMLInputElement>(null)
  const [countryFromNavigator, countryLoaded] = useCountryFromNavigator()
  const [defaultCountryLoaded, setDefaultCountryLoaded] =
    useState<boolean>(false)
  const [leadState] = useSessionState<Partial<Lead>>(SIGNUP_LEAD_STATE_KEY)
  const { formValues, setFormValues, setIsValid, canContinue } =
    useSignUpPageContext()

  // focus on name input on load
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (firstNameInputRef.current) {
        firstNameInputRef.current.focus()
      }
    }, 10)

    return () => clearTimeout(timeoutId)
  }, [])

  const _setFormValues = useCallback(
    (property: SignUpFormProperty, value: string) => {
      if (formValues[property] !== value) {
        setFormValues({
          ...formValues,
          [property]:
            property === SignUpFormProperty.EMAIL
              ? value.trim().toLowerCase()
              : value,
        })
      }
    },
    [formValues, setFormValues],
  )

  // validate the phone number when the country or phone number changes
  const isPhoneValid = useMemo(() => {
    const { phone, country } = formValues
    if (phone && country) {
      return validatePhoneNumber(phone, country)
    }
    return false
  }, [formValues])

  useEffect(() => {
    const { country, email, firstName, lastName, companyName, howHeard } =
      formValues
    setIsValid(
      !!firstName &&
        !!lastName &&
        validateEmail(email) &&
        !!country &&
        isPhoneValid &&
        !!companyName &&
        !!howHeard,
    )
  }, [formValues, isPhoneValid, setIsValid])

  // populate country from browser if not already set (from leadState)
  useEffect(() => {
    if (!defaultCountryLoaded && countryLoaded && !formValues.country) {
      _setFormValues(SignUpFormProperty.COUNTRY, countryFromNavigator || '')
      setDefaultCountryLoaded(true)
    }
  }, [
    countryFromNavigator,
    countryLoaded,
    defaultCountryLoaded,
    _setFormValues,
    formValues.country,
  ])

  // populate form values from leadState if available
  useEffect(() => {
    if (leadState?.value) {
      setFormValues({
        firstName: leadState.value.firstName || '',
        lastName: leadState.value.lastName || '',
        email: leadState.value.email || '',
        phone: leadState.value.phone || '',
        companyName: leadState.value.companyName || '',
        country: leadState.value.country || countryFromNavigator || '',
        howHeard: leadState.value.howHeard || '',
        isHighDelegator: !!leadState.value.isHighDelegator,
        isTeam: !!leadState.value.isTeam,
        isUnsure: !!leadState.value.isUnsure,
      })
    }
  }, [countryFromNavigator, leadState, setFormValues])

  const emptyStringErrorMessage = useCallback(
    (val: string): string =>
      val.trim() === '' ? 'Please fill in this field.' : '',
    [],
  )

  const emptySelectErrorMessage = useCallback((val: string): string => {
    return val === '' ? 'Please select an option.' : ''
  }, [])
  const classNameError = (isInvalid: boolean): string =>
    !canContinue && isInvalid ? 'error' : ''
  const emailErrorMessage = useCallback(
    (email: string): string => {
      const emptyStringMessage = emptyStringErrorMessage(email)
      return (
        emptyStringMessage ||
        (!validateEmail(email)
          ? "Uh-oh! This email address doesn't look right"
          : '')
      )
    },
    [emptyStringErrorMessage],
  )
  const phoneErrorMessage = useCallback(
    (phone: string, country: string): string => {
      const emptyStringMessage = emptyStringErrorMessage(phone)
      return (
        emptyStringMessage ||
        (!validatePhoneNumber(phone, country)
          ? "Oops! The phone number you've entered seems incorrect. Please check the number and country."
          : '')
      )
    },
    [emptyStringErrorMessage],
  )

  return (
    <Outer>
      <Inner>
        <SectionHeader>{'Tell us a little about yourself.'}</SectionHeader>
        <NameInputContainer>
          <Input
            ref={firstNameInputRef}
            placeholder={'First name'}
            value={formValues.firstName}
            onChangeValue={(val) =>
              _setFormValues(SignUpFormProperty.FIRST_NAME, val)
            }
            className={classNameError(
              !!emptyStringErrorMessage(formValues.firstName),
            )}
            errorMessage={
              !canContinue
                ? emptyStringErrorMessage(formValues.firstName)
                : undefined
            }
          />
          <Input
            placeholder={'Last name'}
            value={formValues.lastName}
            onChangeValue={(val) =>
              _setFormValues(SignUpFormProperty.LAST_NAME, val)
            }
            className={classNameError(
              !!emptyStringErrorMessage(formValues.lastName),
            )}
            errorMessage={
              !canContinue
                ? emptyStringErrorMessage(formValues.lastName)
                : undefined
            }
          />
        </NameInputContainer>
        <EmailInput
          placeholder={'Work email'}
          value={formValues.email}
          onChangeValue={(val) => _setFormValues(SignUpFormProperty.EMAIL, val)}
          className={classNameError(!validateEmail(formValues.email))}
          errorMessage={
            !canContinue ? emailErrorMessage(formValues.email) : undefined
          }
        />
        <Input
          placeholder={'Company name'}
          value={formValues.companyName}
          onChangeValue={(val) =>
            _setFormValues(SignUpFormProperty.COMPANY_NAME, val)
          }
          className={classNameError(
            !!emptyStringErrorMessage(formValues.companyName),
          )}
          errorMessage={
            !canContinue
              ? emptyStringErrorMessage(formValues.companyName)
              : undefined
          }
        />
        <Input
          placeholder={'Phone number'}
          value={formValues.phone}
          onChangeValue={(val) => _setFormValues(SignUpFormProperty.PHONE, val)}
          className={classNameError(!isPhoneValid)}
          errorMessage={
            !canContinue
              ? phoneErrorMessage(formValues.phone, formValues.country)
              : undefined
          }
        />
        <SelectInputWithSeparator
          placeholder={'Country'}
          primaryOptions={countries.map(
            ({ code, name }: { code: string; name: string }) => ({
              name,
              value: code,
            }),
          )}
          value={formValues.country}
          onChangeValue={(val) =>
            _setFormValues(SignUpFormProperty.COUNTRY, val)
          }
          $hasErrors={
            !canContinue &&
            !emptyStringErrorMessage(formValues.phone) &&
            !isPhoneValid
          }
        />
        <SelectInputWithSeparator
          placeholder={'How did you hear about Double?'}
          primaryOptions={HEARD_ABOUT_DOUBLE_OPTIONS.map((option) => ({
            name: option,
            value: option,
          }))}
          value={formValues.howHeard}
          onChangeValue={(val) =>
            _setFormValues(SignUpFormProperty.HOW_HEARD, val)
          }
          $hasErrors={!canContinue && !formValues.howHeard}
          errorMessage={
            !canContinue
              ? emptySelectErrorMessage(formValues.howHeard)
              : undefined
          }
        />
      </Inner>
      <AssistantRedirect>
        <CalloutRegular>
          {'Are you an assistant looking for a job? '}
          <NavLink to={CAREERS_PATH}>{'Apply here.'}</NavLink>
        </CalloutRegular>
      </AssistantRedirect>
    </Outer>
  )
}

export default SignUpForm
