import { CardElement } from '@stripe/react-stripe-js'
import React, { useCallback, useEffect, useState } from 'react'

import type { Maybe } from '../../../../../../graphql-types'
import { SIGNUP_LEAD_STATE_KEY } from '../../../../../constants'
import type { Country } from '../../../../../countries'
import countries, { findByCode, isValidState } from '../../../../../countries'
import useCountryFromNavigator from '../../../../../hooks/useCountryFromNavigator'
import useSessionState from '../../../../../hooks/useSessionState'
import type { Lead } from '../../../../../pages/joined'
import DownIcon from '../../../../../system/icons/chevron-down-xs.inline.svg'
import { Color } from '../../../../theme'
import { isZipCodeValid, validateEmail } from '../../../../utils'
import { STATE_REGEX } from '../constants'
import type { BillingFormValues } from '../types'
import { mapStatesToValues } from '../utils'

import PromoCode from './PromoCode'
import {
  AddressOuter,
  AddressTitle,
  CardElementOuter,
  Divider,
  FormRow,
  Input,
  InputContainer,
  Outer,
  SelectOuter,
  StateAndZipCodeOuter,
  Title,
} from './styled'

const STRIPE_CARD_ELEMENT_STYLE = {
  base: {
    fontFamily: '"Inter", "Helvetica Neue", "Helvetica",  "Arial", sans-serif',
    fontWeight: 'normal',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: Color.GRAY_5,
      opacity: 1,
    },
  },
}

const DEFAULT_COUNTRY_OPTION = countries[0]

type Props = {
  emailInputLabel: Maybe<string> | undefined
  showPromoCode: Maybe<boolean> | undefined
  validPromoCodeMessage: Maybe<string> | undefined
  invalidPromoCodeMessage: Maybe<string> | undefined
  onValuesChange: (values: BillingFormValues) => void
}

const PaymentDetails = ({
  emailInputLabel,
  showPromoCode,
  validPromoCodeMessage,
  invalidPromoCodeMessage,
  onValuesChange,
}: Props): JSX.Element => {
  const [countryFromNavigator, countryFromNavigatorLoaded] =
    useCountryFromNavigator()
  const [leadState] = useSessionState<Partial<Lead>>(SIGNUP_LEAD_STATE_KEY)
  const [cardInputFocusedState, setCardInputFocusedState] =
    useState<boolean>(false)

  const [cardComplete, setCardComplete] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [cardholderName, setCardholderName] = useState<string>('')
  const [country, setCountry] = useState<Country>(DEFAULT_COUNTRY_OPTION)
  const [street, setStreet] = useState<string>('')
  const [apt, setApt] = useState<string | undefined>()
  const [city, setCity] = useState<string>('')
  const [state, setState] = useState<string | undefined>()
  const [zipcode, setZipcode] = useState<string>('')

  const [isEmailValidState, setIsEmailValidState] = useState<boolean>()
  const onEmailInputBlur = useCallback(() => {
    if (email) {
      setIsEmailValidState(validateEmail(email))
    }
  }, [email])

  useEffect(() => {
    // set country from lead state if available
    if (leadState?.value?.country) {
      const leadCountry = findByCode(countries, leadState.value.country)
      if (leadCountry) {
        setCountry(leadCountry)
      }
    }
    // otherwise set country from navigator
    else if (countryFromNavigator && countryFromNavigatorLoaded) {
      const navigatorCountry = findByCode(countries, countryFromNavigator)
      if (navigatorCountry) {
        setCountry(navigatorCountry)
      }
    }
  }, [countryFromNavigator, countryFromNavigatorLoaded, leadState])

  const onSelectedCountryChange = useCallback((value: string) => {
    if (value) {
      const newCountryValue = findByCode(countries, value)
      if (newCountryValue) {
        setCountry(newCountryValue)

        // reset state value
        setState(undefined)
      }
    }
  }, [])

  const onZipcodeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.toUpperCase()
      if (isZipCodeValid(value)) {
        setZipcode(value)
      }
    },
    [],
  )

  const isCountryStateSelectionNeeded = !!country?.states?.length
  const [isStateValidState, setIsStateValidState] = useState<boolean>()
  const onStateInputBlur = useCallback(() => {
    if (state) {
      setIsStateValidState(isValidState(country, state))
    }
  }, [state, country])
  const updateStateValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.toUpperCase()
      if (STATE_REGEX.test(value)) {
        setState(value)
      }
    },
    [],
  )

  useEffect(() => {
    onValuesChange(
      mapStatesToValues({
        cardComplete,
        email,
        cardholderName,
        country,
        street,
        city,
        zipcode,
        state,
        apt,
      }),
    )
  }, [
    cardComplete,
    email,
    cardholderName,
    country,
    street,
    city,
    zipcode,
    state,
    apt,
    onValuesChange,
  ])

  return (
    <Outer>
      <Title>{'Please enter your payment details'}</Title>

      <InputContainer>
        <label>
          {emailInputLabel || 'Billing email (to send your monthly invoice)'}
        </label>
        <Input
          id={'billing-email'}
          type={'email'}
          required
          autoFocus
          onChange={(event) => setEmail(event.target.value.trim())}
          onBlur={onEmailInputBlur}
          placeholder={'billing@appleseed.com'}
          $isInvalid={Boolean(email && !isEmailValidState)}
        />
      </InputContainer>

      <InputContainer>
        <label>{'Cardholder Name'}</label>
        <Input
          id={'cardholder-name'}
          type={'text'}
          required
          onChange={(event) => setCardholderName(event.target.value)}
          placeholder={'John Appleseed'}
        />
      </InputContainer>

      <InputContainer>
        <label>{'Credit card'}</label>
        <CardElementOuter $focused={cardInputFocusedState}>
          <CardElement
            options={{ style: STRIPE_CARD_ELEMENT_STYLE }}
            onFocus={() => setCardInputFocusedState(true)}
            onBlur={() => setCardInputFocusedState(false)}
            onChange={({ complete }): void => setCardComplete(complete)}
          />
        </CardElementOuter>
      </InputContainer>

      <Divider />

      <AddressOuter>
        <AddressTitle>{'Billing address'}</AddressTitle>

        <InputContainer>
          <label>{'Country'}</label>
          <SelectOuter>
            <select
              onChange={(e) => {
                onSelectedCountryChange(e.target.value)
              }}
              value={country?.code || ''}
            >
              {(countries || []).map((option) => (
                <option key={option.code} value={option.code}>
                  {option.name}
                </option>
              ))}
            </select>
            <DownIcon />
          </SelectOuter>
        </InputContainer>

        <FormRow>
          <InputContainer>
            <label>{'Street'}</label>
            <Input
              type={'text'}
              required
              onChange={(event) => setStreet(event.target.value)}
              placeholder={'123 Main St.'}
            />
          </InputContainer>

          <InputContainer>
            <label>{'Apt, Unit'}</label>
            <Input
              type={'text'}
              placeholder={'Suite 1002'}
              onChange={(event) => setApt(event.target.value)}
            />
          </InputContainer>
        </FormRow>

        <FormRow>
          <InputContainer>
            <label>{'City'}</label>
            <Input
              type={'text'}
              required
              onChange={(event) => setCity(event.target.value)}
              placeholder={'Brooklyn'}
            />
          </InputContainer>

          <StateAndZipCodeOuter>
            {isCountryStateSelectionNeeded && (
              <InputContainer>
                <label>{country.code === 'CA' ? 'Province' : 'State'}</label>
                <Input
                  type={'text'}
                  maxLength={2}
                  required
                  onChange={updateStateValue}
                  placeholder={country.code === 'CA' ? 'BC' : 'NY'}
                  onBlur={onStateInputBlur}
                  value={state || ''}
                  $isInvalid={Boolean(state && !isStateValidState)}
                />
              </InputContainer>
            )}
            <InputContainer>
              <label>
                {country.code === 'CA' ? 'Postal code' : 'ZIP code'}
              </label>
              <Input
                type={'text'}
                placeholder={'11201'}
                onChange={onZipcodeChange}
                value={zipcode}
                required
              />
            </InputContainer>
          </StateAndZipCodeOuter>
        </FormRow>
      </AddressOuter>

      {showPromoCode && (
        <>
          <Divider />
          <PromoCode
            validPromoCodeMessage={validPromoCodeMessage}
            invalidPromoCodeMessage={invalidPromoCodeMessage}
          />
        </>
      )}
    </Outer>
  )
}

export default PaymentDetails
