import styled from 'styled-components'

import { Color } from '../../components/theme'

export const Outer = styled.div<{ $isOpen: boolean }>`
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  ${({ $isOpen }) =>
    !$isOpen &&
    `
    display: none;
  `}
`

export const CloseIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px;

  svg {
    color: ${Color.WHITE};
    cursor: pointer;
  }
`
