import styled from 'styled-components'

import { mobile } from '../../../mixins'

export const Outer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 52px;
  gap: 16px;

  ${mobile(`
    margin-top: 32px;
    padding: 0 12px;
  `)}
`
