import { graphql, useStaticQuery } from 'gatsby'
import React, { useMemo } from 'react'

import type { GetBlockListsQuery } from '../../../../../graphql-types'

import GenericBlock from './Blocks/GenericBlock'
import GenericOrBlock from './Blocks/GenericOrBlock'
import { ListOuter } from './styled'

export const query = graphql`
  query GetBlockLists {
    blockLists: allStrapiBlockList {
      nodes {
        ...BlockList
      }
    }
  }
`

type Props = {
  customStrapiId: number
}

const BlockListModule = ({ customStrapiId }: Props): JSX.Element => {
  const data = useStaticQuery<GetBlockListsQuery>(query)
  const blocks = useMemo(
    () =>
      data.blockLists.nodes.find(
        (blockList) => blockList.strapi_id === customStrapiId,
      )?.blocks,
    [customStrapiId, data.blockLists.nodes],
  )

  return (
    <ListOuter>
      {blocks?.map((block, index) => {
        switch (block?.__typename) {
          case 'STRAPI__COMPONENT_SIGN_UP_GENERIC_BLOCK':
            return <GenericBlock key={index} {...block} />
          case 'STRAPI__COMPONENT_SIGN_UP_GENERIC_OR_BLOCKS':
            return <GenericOrBlock key={index} {...block} />
          default:
            return null
        }
      })}
    </ListOuter>
  )
}

export default BlockListModule
