/* eslint-disable camelcase */
import { SIGNUP_LEAD_STATE_KEY } from '../../../../constants'
import type { Country } from '../../../../countries'
import { isValidState } from '../../../../countries'
import { getSessionState } from '../../../../hooks/useSessionState'
import type { Lead } from '../../../../pages/joined'
import createSignupBillingSetupIntent from '../../../sign-up/createSignupBillingSetupIntent'
import { trackEvent, validateEmail } from '../../../utils'

import type { BillingAddress, BillingFormValues } from './types'

type BillingFormStates = {
  cardComplete: boolean
  email: string
  cardholderName: string
  country: Country
  street: string
  city: string
  zipcode: string
  state?: string
  apt?: string
}

export const mapStatesToValues = ({
  cardComplete,
  email,
  country,
  cardholderName,
  street,
  city,
  zipcode,
  state,
  apt,
}: BillingFormStates): BillingFormValues => ({
  name: cardholderName,
  cardComplete,
  email,
  address: {
    line1: street,
    line2: apt,
    city,
    state,
    postalCode: zipcode,
    country,
  },
})

export const mapBillingAddressToStripeAddress = ({
  country,
  postalCode,
  ...rest
}: BillingAddress): Omit<BillingAddress, 'country' | 'postalCode'> & {
  country: string
  postal_code: string
} => ({
  ...rest,
  country: country.code,
  postal_code: postalCode,
})

export const validateBillingFormValues = ({
  cardComplete,
  name,
  email,
  address: { line1, city, state, postalCode, country },
}: BillingFormValues): boolean => {
  const isCountryStateSelectionNeeded = !!country.states?.length

  const isEmailValid = email && validateEmail(email)
  const isStateStateValid =
    !isCountryStateSelectionNeeded || (state && isValidState(country, state))
  const isValid = !!(
    cardComplete &&
    isEmailValid &&
    name &&
    country &&
    line1 &&
    city &&
    postalCode &&
    isStateStateValid
  )

  return isValid
}

export const createStripeIntent = async (): Promise<string | undefined> => {
  const leadState = getSessionState<Lead>(SIGNUP_LEAD_STATE_KEY)
  const leadRecordId = leadState?.value?.leadRecordId

  if (leadRecordId) {
    const result = await createSignupBillingSetupIntent({
      leadRecordId,
    })

    trackEvent('billing_intent_setup', { leadRecordId })

    return result?.clientSecret
  }
}
