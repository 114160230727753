// sign-up page slugs
export const SIGN_UP_PREFIX = '/sign-up'
export const ABOUT_YOU_SLUG = 'about-you'
export const WHATS_NEXT_QUESTIONNAIRE = 'whats-next-questionnaire'
export const BILLING_SLUG = 'billing'
export const FIND_MY_ASSISTANT_SLUG = 'find-my-assistant'

// URL query param keys
export const LEAD_ID_KEY = 'leadId'
export const DISCOUNT_CODE_KEY = 'utm_campaign'

// session storage keys
export const LEAD_RECORD_ID_PARAM = 'lead_record_id'
