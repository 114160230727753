import React from 'react'
import styled, { keyframes } from 'styled-components'

import { Color } from '../../components/theme'

const spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const LoaderContainer = styled.div<{ $fullscreen?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;

  ${({ $fullscreen }) =>
    $fullscreen &&
    `
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 100;
  `}
`

const LoaderSpinner = styled.div`
  width: 50px;
  height: 50px;
  border: 3px solid ${Color.GRAY_1};
  border-top: 3px solid ${Color.PURPLE_5};
  border-radius: 50%;
  animation: ${spinner} 0.5s linear infinite;
`

type Props = {
  className?: string
  style?: React.CSSProperties
  $fullscreen?: boolean
}

const Loader = (props: Props): JSX.Element => (
  <LoaderContainer {...props}>
    <LoaderSpinner />
  </LoaderContainer>
)

export default Loader
