import { MOBILE_WIDTH } from '../components/theme'

import useWindowWidth from './useWindowWidth'

const useIsMobileView = (): boolean => {
  const windowWidth = useWindowWidth()

  return windowWidth <= MOBILE_WIDTH
}

export default useIsMobileView
