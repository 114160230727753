/**
 * Shared types/enums between all Strapi components
 */

// Strapi doesn't provide options from an enumerated list
export enum ButtonType {
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
  BORDER = 'Border',
  PRIMARY_SMALL = 'PrimarySmall',
  SECONDARY_SMALL = 'SecondarySmall',
  BORDER_SMALL = 'BorderSmall',
}

export enum StepStatus {
  EMPTY = 'empty',
  HALF = 'half',
  FULL = 'full',
}
