import Markdown from 'markdown-to-jsx'
import styled, { css } from 'styled-components'

import { mobile, screenSize3 } from '../../../../mixins'
import { Color } from '../../../../theme'
import {
  bodyMediumStyle,
  calloutMediumStyle,
  calloutSemiboldStyle,
  captionRegularStyle,
} from '../../../../typography'

export const Outer = styled.div`
  width: 100%;
  max-width: 352px;
  padding: 24px;
  border-radius: 12px;
  background-color: ${Color.GRAY_1};

  display: flex;
  flex-direction: column;
  row-gap: 24px;

  ${screenSize3(css`
    max-width: max-content;
  `)}

  ${mobile(css`
    padding: 16px;
    row-gap: 16px;
  `)}
`

export const Title = styled.div`
  ${bodyMediumStyle({ noResponsive: true })}
  color: ${Color.BLACK};
`

export const DetailSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    ${calloutMediumStyle({ noResponsive: true })}
    color: ${Color.BLACK};
  }
`

export const SectionDescription = styled(Markdown)`
  margin: 0;
  ${captionRegularStyle};
  color: ${Color.GRAY_7};

  a {
    text-decoration: underline;
  }
`

export const Divider = styled.hr`
  width: 100%;
  margin: 0;
  border: 0;
  border-top: 1px solid ${Color.GRAY_3};
`

export const SummaryRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    ${calloutSemiboldStyle({ noResponsive: true })}
    color: ${Color.GREEN_5};
  }
`
