import React from 'react'

import type { Strapi_SignUp_ActionButtonBlockFragment } from '../../../../../graphql-types'
import { SubheadRegular } from '../../../typography'
import Button from '../Button'
import { ACTIONS } from '../actions'
import type { ButtonType } from '../types'

import { Outer, TextContainer, Title } from './styled'

const ActionButtonBlock = ({
  title,
  description,
  buttons,
}: Strapi_SignUp_ActionButtonBlockFragment): JSX.Element => (
  <Outer>
    <TextContainer>
      <Title>{title}</Title>
      <SubheadRegular>{description}</SubheadRegular>
    </TextContainer>
    {buttons?.map((button, index) => {
      if (!button) return null

      const { label, type, navigateTo, action, calendlyLink } = button
      const buttonAction = action ? ACTIONS[action] : undefined
      const hasAction = buttonAction || navigateTo || calendlyLink
      return (
        type &&
        hasAction && (
          <Button
            key={`${label}-${index}`}
            type={type as ButtonType}
            label={label || ''}
            navigateTo={navigateTo || undefined}
            action={buttonAction}
            calendlyLink={calendlyLink}
          />
        )
      )
    })}
  </Outer>
)

export default ActionButtonBlock
