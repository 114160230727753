import type { FlattenSimpleInterpolation } from 'styled-components'
import { css } from 'styled-components'

import { mobile } from '../../components/mixins'
import { Color } from '../../components/theme'

export type ButtonProps = {
  disabled?: boolean
}

const commonButtonStyle = (
  props: ButtonProps,
): FlattenSimpleInterpolation => css`
  display: flex;
  justify-content: center;
  align-items: center;

  ${props.disabled &&
  `
    cursor: default;
  `}
`

export const primaryStyle = (
  props: ButtonProps,
): FlattenSimpleInterpolation => css`
  ${commonButtonStyle(props)}

  box-shadow: none;
  background-color: ${props.disabled ? Color.GRAY_1 : Color.PURPLE_5};
  color: ${props.disabled ? Color.GRAY_6 : Color.WHITE};

  // enable hover style only if button is not disabled
  ${!props.disabled &&
  `
    &:hover {
      background-color: ${Color.PURPLE_6};
    }
  `}

  p {
    color: ${props.disabled ? Color.GRAY_6 : Color.WHITE};
  }
`

export const bigButtonStyle = (
  props: ButtonProps,
): FlattenSimpleInterpolation => css`
  ${commonButtonStyle(props)}

  height: 56px;
  padding: 0 56px;

  border-radius: 12px;

  ${mobile(`
    height: 52px;
  `)}
`
