import styled, { css } from 'styled-components'

import {
  EmailInput as _EmailInput,
  Input as _Input,
  SelectInputWithSeparator as _SelectInputWithSeparator,
} from '../../../forms'
import { desktop, mobile } from '../../../mixins'
import { Color } from '../../../theme'
import { BodySemibold } from '../../../typography'

const inputStyle = css`
  margin: 16px 0 0 0;
  transition: box-shadow 200ms ease;

  &:focus {
    box-shadow: inset 0 0 0 2px ${Color.PURPLE_4};
  }

  &.error:not(:focus) {
    box-shadow: inset 0 0 0 2px ${Color.FLAMINGO_5};
  }

  ${desktop(`
    height: 56px;
  `)}
`

export const Outer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 52px;

  ${mobile(`
    margin-top: 32px;
    padding: 0 12px;
  `)}
`

export const Inner = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  max-width: 528px;
`

export const SectionHeader = styled(BodySemibold)<{ required?: boolean }>`
  ${mobile(`
    font-size: 15px;
    line-height: 18px;
    margin-left: 0;
    margin-right: 0;
  `)}

  ${({ required }) =>
    !!required &&
    `
    &:after {
      content: '*';
    }
  `}
`

export const NameInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;

  ${mobile(`
    flex-direction: column;
    column-gap: 0;
  `)}
`

export const Input = styled(_Input)`
  ${inputStyle}
`

export const EmailInput = styled(_EmailInput)`
  ${inputStyle}
`

export const SelectInputWithSeparator = styled(_SelectInputWithSeparator)<{
  $hasErrors?: boolean
}>`
  ${inputStyle}

  ${({ $hasErrors }) =>
    !!$hasErrors &&
    `
    select {
      box-shadow: inset 0 0 0 2px ${Color.FLAMINGO_5};
    }
  `}
`

export const AssistantRedirect = styled.div`
  flex: 1 0 0;
  margin-top: 40px;
  text-align: center;

  a {
    color: ${Color.PURPLE_6};
    text-decoration: none;
  }

  ${mobile(`
    margin-top: 19px;
  `)}
`
