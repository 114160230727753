import styled from 'styled-components'

import { maxScreenWidth } from '../../../mixins'
import { Color } from '../../../theme'

export const Outer = styled.div`
  width: 100%;
  margin: auto;
  justify-content: center;
`

export const Inner = styled.div`
  margin-top: -72px;
  ${maxScreenWidth(650)(`
    margin-top: 0;
    border: 2px solid ${Color.GRAY_2};
    border-radius: 8px;
    box-shadow: 0 1px 8px 0 rgb(0 0 0/8%);
    overflow: hidden;
  `)}
`
