import styled from 'styled-components'

import { maxScreenWidth, mobile } from '../../../mixins'
import { Color } from '../../../theme'

const columnView = maxScreenWidth(900)

export const Outer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  column-gap: 30px;
  width: 100%;
  margin-top: 42px;

  ${columnView(`
    flex-direction: column;
    align-items: center;
    row-gap: 32px;
  `)}
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 640px;
  overflow: hidden;

  background: ${Color.WHITE};
  border: 1px solid ${Color.GRAY_3};
  border-radius: 16px;

  ${columnView(`
    width: 100%;
  `)}

  img {
    width: 100%;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 0 20px 24px;

  ${mobile(`
    padding: 0 16px 24px;
  `)}
`
