import styled from 'styled-components'

import { mobile } from '../../mixins'

export const Outer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1264px;
  padding: 0 24px;

  > * {
    margin-top: 72px;
  }

  ${mobile(`
    padding: 0 12px;

    > *:first-child {
      margin-top: 32px;
    }

    > *:not(first-child) {
      margin-top: 56px;
    }
  `)}
`
