import { requestGraphql } from '../api'

type SaveSignupBillingInput = {
  leadRecordId: string
  address: {
    country: string
    city: string
    state?: string
    postalCode: string
    line1: string
    line2?: string
  }
  paymentMethodId: string
}

interface Result {
  isSuccess: boolean
}

const saveSignupBilling = async (
  input: SaveSignupBillingInput,
): Promise<undefined | Result> => {
  const data = await requestGraphql<{
    saveSignupBilling: Result
  }>({
    operationName: 'SaveSignupBilling',
    variables: {
      input,
    },
    query: `
      mutation SaveSignupBilling($input: SaveSignupBillingInput!) {
        saveSignupBilling(input: $input) {
          isSuccess
        }
      }
    `,
  })

  return data?.saveSignupBilling
}

export default saveSignupBilling
