import { navigate } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import type {
  Maybe,
  Strapi_BlockListModuleFragment,
  Strapi_HeaderFragment,
  Strapi_ImageCardListFragment,
  Strapi_RowModuleFragment,
  Strapi_SignUp_ActionButtonBlockFragment,
  Strapi_SignUp_BillingFragment,
  Strapi_SignUp_ButtonGroupFragment,
  Strapi_SignUp_CalendlyEmbedFragment,
  Strapi_SignUp_CreditCardFragment,
  Strapi_SignUp_FaqCardsFragment,
  Strapi_SignUp_LeadClassificationFormFragment,
  Strapi_SignUp_PricingFragment,
  Strapi_SignUp_SignUpFormFragment,
  Strapi_SignUp_VideoPlayerFragment,
  Strapi_TextWithImageListFragment,
  Strapi_TypeformFragment,
} from '../../../../graphql-types'
import { mobile } from '../../mixins'
import { trackEvent } from '../../utils'
import Typeform from '../CustomModule/Typeform'
import FaqCards from '../FaqCards'

import ActionButtonBlock from './ActionButtonBlock'
import Billing from './Billing'
import BlockListModule from './BlockListModule'
import ButtonGroup from './ButtonGroup'
import CalendlyEmbed from './CalendlyEmbed'
import CreditCard from './CreditCard'
import Header from './Header'
import ImageCardList from './ImageCardList'
import LeadClassificationForm from './LeadClassificationForm'
import Pricing from './Pricing'
import RowModule from './RowModule'
import SignUpForm from './SignUpForm'
import TextWithImageList from './TextWithImageList'
import VideoPlayer from './VideoPlayer'

const TypeformOuter = styled.div`
  width: calc(100% + 48px);
  margin: 0;

  & > div {
    padding: 0;
  }

  ${mobile(`
    // extend to fill width of page
    width: calc(100% + 24px);
  `)}
`

// keep this updated to reflect possible modules in Strapi
type StrapiModule = Maybe<
  | Strapi_SignUp_VideoPlayerFragment
  | Strapi_SignUp_FaqCardsFragment
  | Strapi_SignUp_SignUpFormFragment
  | Strapi_TypeformFragment
  | Strapi_SignUp_ButtonGroupFragment
  | Strapi_SignUp_CalendlyEmbedFragment
  | Strapi_SignUp_CreditCardFragment
  | Strapi_SignUp_ActionButtonBlockFragment
  | Strapi_SignUp_PricingFragment
  | Strapi_TextWithImageListFragment
  | Strapi_RowModuleFragment
  | Strapi_ImageCardListFragment
  | Strapi_HeaderFragment
  | Strapi_BlockListModuleFragment
  | Strapi_SignUp_BillingFragment
  | Strapi_SignUp_LeadClassificationFormFragment
>
export type StrapiModules = Maybe<StrapiModule[]>

type ModuleProps = {
  module?: StrapiModule
}

const RawModule = ({ module }: ModuleProps): JSX.Element | null => {
  if (!module?.__typename) return null

  switch (module.__typename) {
    case 'STRAPI__COMPONENT_SIGN_UP_VIDEO_PLAYER':
      return <VideoPlayer {...module} />
    case 'STRAPI__COMPONENT_SIGN_UP_FAQ_CARDS': {
      return <FaqCards {...module} />
    }
    case 'STRAPI__COMPONENT_SIGN_UP_LEAD_CLASSIFICATION_FORM':
      return <LeadClassificationForm {...module} />
    case 'STRAPI__COMPONENT_SIGN_UP_SIGN_UP_FORM': {
      return <SignUpForm />
    }
    case 'STRAPI__COMPONENT_GENERAL_TYPEFORM':
      return module.formId ? (
        <TypeformOuter>
          <Typeform
            formId={module.formId}
            height={module.height}
            hideHeader={module.hideHeader}
            hideProgressBar={module.hideProgressBar}
            onSubmit={() => {
              trackEvent('matching_survey_completed')

              module.navigateToOnSubmit &&
                Promise.resolve(navigate(module.navigateToOnSubmit)).catch(
                  console.error,
                )
            }}
          />
        </TypeformOuter>
      ) : null
    case 'STRAPI__COMPONENT_SIGN_UP_BUTTON_GROUP':
      return module.buttons ? <ButtonGroup buttons={module.buttons} /> : null
    case 'STRAPI__COMPONENT_SIGN_UP_BILLING':
      return <Billing {...module} />
    case 'STRAPI__COMPONENT_SIGN_UP_CALENDLY_EMBED':
      return <CalendlyEmbed {...module} />
    case 'STRAPI__COMPONENT_SIGN_UP_CREDIT_CARD':
      return <CreditCard {...module} />
    case 'STRAPI__COMPONENT_SIGN_UP_ACTION_BUTTON_BLOCK':
      return <ActionButtonBlock {...module} />
    case 'STRAPI__COMPONENT_SIGN_UP_PRICING':
      return <Pricing {...module} />
    case 'STRAPI__COMPONENT_GENERAL_TEXT_WITH_IMAGE_LIST':
      return <TextWithImageList {...module} />
    case 'STRAPI__COMPONENT_GENERAL_ROW_MODULE':
      return module.customStrapiId ? (
        <RowModule customStrapiId={module.customStrapiId} />
      ) : null
    case 'STRAPI__COMPONENT_GENERAL_IMAGE_CARD_LIST':
      return <ImageCardList {...module} />
    case 'STRAPI__COMPONENT_GENERAL_HEADER':
      return <Header {...module} />
    case 'STRAPI__COMPONENT_GENERAL_BLOCK_LIST_MODULE':
      return module.customStrapiId ? (
        <BlockListModule customStrapiId={module.customStrapiId} />
      ) : null
  }
}

const StrapiModule = ({ ...props }: ModuleProps): JSX.Element => (
  <RawModule {...props} />
)

export default StrapiModule
