import React from 'react'

import type { StrapiModules } from '../../modules/strapi'
import StrapiModule from '../../modules/strapi'

import { Outer } from './styled'

export type ContentPageProps = {
  modules?: StrapiModules
}

type Props = {
  modules?: StrapiModules
}

const SignUpPageContent = ({ modules }: Props): JSX.Element => (
  <Outer>
    {modules?.map(
      (module, index) =>
        !!module && (
          <StrapiModule
            key={`${module.__typename || ''}${index}`}
            module={module}
          />
        ),
    )}
  </Outer>
)

export default SignUpPageContent
