import styled, { css } from 'styled-components'

import { mobile, screenSize3 } from '../../../mixins'
import { Color } from '../../../theme'
import { bodyMediumStyle } from '../../../typography'

export const Outer = styled.div`
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${screenSize3(css`
    max-width: 640px;
  `)}

  // override margin-top inherited from SignUpPageContent
  margin-top: 40px !important;
  ${mobile(`
    margin-top: 32px !important;
  `)}
`

export const ErrorMessage = styled.div`
  ${bodyMediumStyle({ noResponsive: true })}
  margin-bottom: 8px;
  color: ${Color.FLAMINGO_6};
  text-align: center;
`

export const Inner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 32px;

  ${screenSize3(css`
    flex-direction: column;
    row-gap: 24px;
  `)}
`
