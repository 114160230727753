import styled from 'styled-components'

import { Color } from '../theme'
import { captionSemiboldStyle } from '../typography'

export const DESKTOP_LABEL_WIDTH = 96

export const Outer = styled.div<{ collapseStepLabels: boolean }>`
  width: 100%;
  max-width: 960px;
  display: flex;
  justify-content: space-between;

  ${({ collapseStepLabels }) =>
    collapseStepLabels &&
    `
    padding: 9px 40px;
    z-index: 1;
  `}
`

export const Item = styled.div<{ collapseStepLabels: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${DESKTOP_LABEL_WIDTH}px;

  ${({ collapseStepLabels }) =>
    collapseStepLabels &&
    `
    width: 24px;
  `}
`

export const IconAndSegmentOuter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const Segment = styled.div<{ highlighted?: boolean; hide?: boolean }>`
  flex: 1;
  height: 3px;

  background-color: ${({ highlighted }) =>
    highlighted ? Color.PURPLE_5 : Color.GRAY_3};

  ${({ hide }) =>
    hide &&
    `
    // hide to keep the positioning of the circle icon
    visibility: hidden;
  `}
`

export const Label = styled.div<{ highlighted: boolean }>`
  width: max-content;
  ${captionSemiboldStyle({})}

  margin-top: 12px;
  text-align: center;

  ${({ highlighted }) => `
    color: ${highlighted ? Color.PURPLE_5 : Color.GRAY_5};
  `}
`
