import { useState, useEffect, useCallback } from 'react'

export function setSessionState<V>(key: string, value: V): void {
  try {
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem(
        key,
        JSON.stringify({
          value,
        }),
      )
    }
  } catch (error) {
    console.error('Failed to write value into session storage', error)
  }
}

export function getSessionState<V>(key: string): undefined | { value?: V } {
  try {
    if (typeof window !== 'undefined') {
      const storageValue = window.sessionStorage.getItem(key)
      if (storageValue) {
        const parsedStorageValue = JSON.parse(storageValue) as { value?: V }
        if (parsedStorageValue && 'value' in parsedStorageValue) {
          return parsedStorageValue
        }
      }
    }
  } catch (error) {
    console.error('Failed to read param from storage', error)
  }
}

export default function useSessionState<V>(
  key: string,
): [undefined | { value?: V }, (value: V, overwrite?: boolean) => void] {
  const [state, setNewState] = useState<{ value?: V } | undefined>(
    getSessionState<V>(key) || {},
  )

  useEffect(() => {
    setNewState(getSessionState<V>(key) || {})
  }, [key, setNewState])

  const setState = useCallback(
    (value: V, overwrite?: boolean) => {
      const oldStateValue = state?.value
      const newStateValue = {
        ...(!overwrite && oldStateValue),
        ...value,
      }

      if (JSON.stringify(newStateValue) !== JSON.stringify(oldStateValue)) {
        setNewState({
          value: newStateValue,
        })
        setSessionState(key, newStateValue)
      }
    },
    [key, state],
  )

  return [state, setState]
}
