import compact from 'lodash/compact'
import React, { useCallback, useMemo, useState } from 'react'

import type { Strapi_SignUp_FaqCardsFragment } from '../../../../graphql-types'
import CollapsibleContainer from '../../../system/CollapsibleContainer'
import MinusIcon from '../../../system/icons/minus-s.inline.svg'
import PlusIcon from '../../../system/icons/plus-s.inline.svg'
import { BodySemibold } from '../../typography'
import { renderTextWithLineBreaksAndB } from '../../utils'

import {
  Card,
  CardsContainer,
  CardTitleContainer,
  Description,
  Outer,
  Title,
} from './styled'

const FaqCards = ({
  cards,
  title,
}: Strapi_SignUp_FaqCardsFragment): JSX.Element => {
  const [expandedCardIndex, setExpandedCardIndex] = useState<number>(-1)

  const faqs = useMemo(
    () =>
      compact(
        (cards || []).map((card) =>
          card?.question && card.answer?.data?.answer
            ? {
                question: card.question,
                answer: card.answer.data.answer,
              }
            : null,
        ),
      ),
    [cards],
  )

  const toggleCard = useCallback(
    (index) => {
      setExpandedCardIndex(expandedCardIndex === index ? -1 : index)
    },
    [expandedCardIndex],
  )

  return (
    <Outer>
      {title && <Title>{title}</Title>}
      <CardsContainer>
        {faqs.map((faq, index) => {
          const isExpanded = expandedCardIndex === index

          return (
            <Card key={index} onClick={() => toggleCard(index)}>
              <CardTitleContainer>
                <BodySemibold>{faq.question}</BodySemibold>
                {isExpanded ? <MinusIcon /> : <PlusIcon />}
              </CardTitleContainer>
              <CollapsibleContainer isExpanded={isExpanded}>
                <Description>
                  {renderTextWithLineBreaksAndB(faq.answer)}
                </Description>
              </CollapsibleContainer>
            </Card>
          )
        })}
      </CardsContainer>
    </Outer>
  )
}

export default FaqCards
