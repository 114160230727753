import React from 'react'

import type { Strapi_SignUp_GenericBlockFragment } from '../../../../../../graphql-types'
import { BodySemibold } from '../../../../typography'
import Button from '../../Button'
import { ACTIONS } from '../../actions'
import { getStrapiMediaSourceUrl } from '../../helpers'
import type { ButtonType } from '../../types'

import { Image, TextContainer, Description, ContentContainer } from './styled'

const BlockContent = (
  block: Strapi_SignUp_GenericBlockFragment,
): JSX.Element => {
  // handle prod & local CMS instances
  const imgSrc = getStrapiMediaSourceUrl(block?.image?.url)

  return (
    <>
      {imgSrc && (
        <div>
          <Image src={imgSrc} />
        </div>
      )}
      <ContentContainer $hasImage={!!imgSrc}>
        <TextContainer>
          {block?.title && <BodySemibold>{block.title}</BodySemibold>}
          {block.description?.data?.description && (
            <Description
              markdown={block.description.data.description}
              openLinksInNewTab
            />
          )}
        </TextContainer>
        {block.button?.type && (
          <Button
            type={block.button.type as ButtonType}
            label={block.button.label || ''}
            navigateTo={block.button.navigateTo || undefined}
            action={
              block.button.action ? ACTIONS[block.button.action] : undefined
            }
            calendlyLink={block.button.calendlyLink}
          />
        )}
      </ContentContainer>
    </>
  )
}

export default BlockContent
