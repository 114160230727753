import styled from 'styled-components'

import { calloutMediumStyle } from '../../components/typography'

import type { ButtonProps } from './shared'
import { primaryStyle } from './shared'

const ExtraSmallButtonPrimary = styled.button<ButtonProps>`
  ${primaryStyle}
  ${calloutMediumStyle({ noColor: true })}

  // don't let responsive typography overwrite padding
  padding: 0 12px !important;

  height: 36px;
  border-radius: 10px;
`

export default ExtraSmallButtonPrimary
