import styled, { css } from 'styled-components'

import { mobile } from '../../../mixins'
import { Color } from '../../../theme'
import { bodySemiboldStyle } from '../../../typography'

const buttonStyle = css`
  ${bodySemiboldStyle({ noColor: true })}

  height: 56px;
  width: 225px;
  justify-content: center;
  align-items: center;

  ${mobile(`
    height: 56px;
    width: 100%;
  `)}
`

// TODO: talk to Alex - we are using 2 different styles for "BigButton". Do we need to keep them separate?
export const PrimaryButton = styled.button`
  ${buttonStyle}

  background: ${Color.PURPLE_5};
  border-radius: 12px;
  box-shadow: none;
  color: ${Color.WHITE};

  p {
    color: ${Color.WHITE};
  }
`

export const SecondaryButton = styled.button`
  ${buttonStyle}

  background: ${Color.WHITE};
  border-radius: 12px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1),
    inset 0px -2px 2px rgba(0, 0, 0, 0.05);
  color: ${Color.PURPLE_5};

  p {
    color: ${Color.PURPLE_5};
  }
`

export const BorderButton = styled.button`
  ${buttonStyle}

  background: ${Color.WHITE};
  border-radius: 12px;
  border: 1.5px solid ${Color.PURPLE_5};
  box-shadow: none;
  color: ${Color.PURPLE_5};

  p {
    color: ${Color.PURPLE_5};
  }
`
