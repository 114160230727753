import styled from 'styled-components'

import type { ButtonProps } from './shared'
import { bigButtonStyle, primaryStyle } from './shared'

const BigButtonPrimary = styled.button<ButtonProps>`
  ${primaryStyle}
  ${bigButtonStyle}
`

export default BigButtonPrimary
