import { navigate } from 'gatsby'
import React, { useState } from 'react'

import Modal from '../../../system/Modal'
import BigButtonText from '../../../system/buttons/BigButtonText'
import CalendlyWrapper from '../../modules/strapi/CalendlyWrapper'
import { BodyMedium, BodySemibold } from '../../typography'

import { ContinueButton, Outer } from './styled'

type Props = {
  canContinue: boolean
  onContinueClick: () => void
  calendlyLink?: string
}

const Footer = ({
  canContinue,
  onContinueClick,
  calendlyLink,
}: Props): JSX.Element => {
  const goBack = (): Promise<void> => navigate(-1)
  const [calendlyIsOpen, setCalendlyIsOpen] = useState(false)
  const openModal = (): void => {
    setCalendlyIsOpen(true)
  }
  const closeModal = (): void => {
    setCalendlyIsOpen(false)
  }

  return (
    <Outer>
      <BigButtonText onClick={goBack}>
        <BodyMedium>{'Back'}</BodyMedium>
      </BigButtonText>
      {calendlyLink && (
        <Modal isOpen={calendlyIsOpen} onClose={closeModal} prerenderContent>
          <CalendlyWrapper calendlyLink={calendlyLink} />
        </Modal>
      )}
      <ContinueButton
        onClick={calendlyLink ? openModal : onContinueClick}
        disabled={!canContinue}
      >
        <BodySemibold>{'Continue'}</BodySemibold>
      </ContinueButton>
    </Outer>
  )
}

export default Footer
