import React from 'react'

import type { Strapi_ImageCardListFragment } from '../../../../../graphql-types'
import { CalloutRegular, Heading3 } from '../../../typography'
import { getStrapiMediaSourceUrl } from '../helpers'

import { CardContainer, Outer, TextContainer } from './styled'

const ImageCardList = ({
  cards,
}: Strapi_ImageCardListFragment): JSX.Element => (
  <Outer>
    {cards?.map((card, index) => {
      // handle prod & local CMS instances
      const imageSrc = getStrapiMediaSourceUrl(card?.image?.url)

      return (
        <CardContainer key={index}>
          <img src={imageSrc} />
          <TextContainer>
            <Heading3>{card?.title}</Heading3>
            <CalloutRegular>
              {card?.description?.data?.description}
            </CalloutRegular>
          </TextContainer>
        </CardContainer>
      )
    })}
  </Outer>
)

export default ImageCardList
