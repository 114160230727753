/* eslint-disable camelcase */
import { DateTime } from 'luxon'
import { useCallback, useState } from 'react'

import type { Maybe, SignUpPageFragment } from '../../../../graphql-types'
import signup from '../../../components/join-forms/signup'
import { setTypeformParams } from '../../../components/modules/CustomModule/Typeform'
import {
  formatPhoneNumber,
  trackEvent,
  validatePhoneNumber,
} from '../../../components/utils'
import useGclid from '../../../hooks/useGclid'
import useQueryOrStorageParam from '../../../hooks/useQueryOrStorageParam'
import useUtm from '../../../hooks/useUtm'
import type { Lead } from '../../../pages/joined'
import { LEAD_RECORD_ID_PARAM } from '../constants'
import type { SignUpFormProps } from '../types'

type Props = {
  signUpPage: Maybe<SignUpPageFragment> | undefined
  pageContext: { nextSlug?: string }
  canContinue: boolean
  setLeadState: (values: Partial<Lead>, overwrite: boolean) => void
  navigate: (path: string) => Promise<void>
}

type ReturnProps = {
  formValues: SignUpFormProps
  setFormValues: (values: Partial<SignUpFormProps>) => void
  submitForm: () => Promise<void>
}

export const DEFAULT_FORM_VALUES: SignUpFormProps = {
  firstName: '',
  lastName: '',
  email: '',
  country: '',
  phone: '',
  companyName: '',
  howHeard: '',
}

const useSignUpForm = ({ canContinue, setLeadState }: Props): ReturnProps => {
  const gclid = useGclid()
  const utm = useUtm()
  const leadRecordId = useQueryOrStorageParam(LEAD_RECORD_ID_PARAM, undefined, {
    persist: true,
  })

  const [formValues, _setFormValues] =
    useState<SignUpFormProps>(DEFAULT_FORM_VALUES)
  const setFormValues = useCallback((values: Partial<SignUpFormProps>) => {
    _setFormValues((prev) => ({ ...prev, ...values }))
  }, [])

  const submitForm = useCallback(async () => {
    const {
      firstName,
      lastName,
      email,
      country,
      phone,
      companyName,
      howHeard,
      isHighDelegator,
      isTeam,
      isUnsure,
    } = formValues

    const phoneIsValid = validatePhoneNumber(phone, country)

    const fullName = `${firstName} ${lastName}`
    const formattedPhone = formatPhoneNumber(phone, country)

    const cleanCompany = companyName.trim()

    if (canContinue) {
      trackEvent('signup', {
        name: fullName,
        email: email,
        country: country,
        phone: formattedPhone || '',
        companyName: cleanCompany,
        howHeard: howHeard,
        isHighDelegator: String(isHighDelegator),
        isTeam: String(isTeam),
        isUnsure: String(isUnsure),
      })

      // save sign up
      const result = await signup({
        leadRecordId: leadRecordId || undefined,
        email: email,
        givenName: firstName,
        familyName: lastName,
        country: country,
        phone: phoneIsValid ? formattedPhone : undefined,
        companyName: cleanCompany,
        isTeam,
        isHighDelegator,
        isUnsure,
        gclid,
        utm,
        howHeard,
        timeZone: DateTime.local().zoneName,
      }).catch(console.error)

      // update typeform args
      setTypeformParams({
        name: fullName,
        email: email,
        phone: (phoneIsValid && formattedPhone) || '',
        company_name: cleanCompany,
        lead_record_id: result?.leadRecordId || leadRecordId || '',
        hide_name: String(!!fullName),
        how_heard: howHeard,
        gclid,
        utm_source: utm?.source || '',
        utm_medium: utm?.medium || '',
        utm_campaign: utm?.campaign || '',
        utm_term: utm?.term || '',
        utm_content: utm?.content || '',
      })

      trackEvent('submit_waitlist_form')
      setLeadState(
        {
          firstName,
          lastName,
          fullName,
          email,
          country,
          phone, // keep user's phone format for if they come back to sign-up form
          companyName: cleanCompany,
          leadRecordId: result?.leadRecordId || leadRecordId || '',
          howHeard,
          isHighDelegator,
          isTeam,
          isUnsure,
        },
        false,
      )
    }
  }, [canContinue, formValues, gclid, leadRecordId, setLeadState, utm])

  return {
    formValues,
    setFormValues,
    submitForm,
  }
}

export default useSignUpForm
