/* eslint-disable camelcase */
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { graphql, navigate as gatsbyNavigate } from 'gatsby'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import type { SignUpPageBySlugQuery } from '../../../graphql-types'
import Page from '../../components/layout/Page'
import type { BillingFormValues } from '../../components/modules/strapi/Billing/types'
import markLeadReadyToBeMatched from '../../components/modules/strapi/markLeadReadyToBeMatched'
import SignUpFooter from '../../components/sign-up/Footer'
import SignUpPageContent from '../../components/sign-up/SignUpPageContent'
import StepsIndicatorWrapper from '../../components/sign-up/StepsIndicatorWrapper'
import { supportedCountries } from '../../countries'
import type { Lead } from '../../pages/joined'
import Loader from '../../system/Loader'

import {
  ABOUT_YOU_SLUG,
  BILLING_SLUG,
  FIND_MY_ASSISTANT_SLUG,
  SIGN_UP_PREFIX,
} from './constants'
import useBillingPage from './hooks/useBillingPage'
import useSignUpForm, { DEFAULT_FORM_VALUES } from './hooks/useSignUpForm'
import useSignUpParams from './hooks/useSignUpParams'
import {
  Inner,
  Loader as StyledLoader,
  Outer,
  StyledHero,
  TitleImage,
} from './styled'
import type { SignUpFormProps } from './types'

export const query = graphql`
  query SignUpPageBySlug($slug: String!) {
    signUpPage: strapiSignUpPage(slug: { eq: $slug }) {
      ...SignUpPage
    }
    pages: allStrapiSignUpPage(sort: { fields: index }, limit: 1) {
      nodes {
        slug
      }
    }
  }
`

interface Props {
  data: SignUpPageBySlugQuery
  pageContext: {
    slug: string
    nextSlug?: string
  }
}

interface SignUpPageContextValue {
  formValues: SignUpFormProps
  setFormValues: (values: Partial<SignUpFormProps>) => void
  setLeadState: (values: Partial<Lead>, overwrite: boolean) => void
  canContinue: boolean
  continueToNextPage: () => void
  setIsValid: (value: boolean) => void
  setValidCouponCode: (value: string | null) => void
  setClientSecret: (value: string) => void
  setBillingFormValues: (values: BillingFormValues) => void
  billingError?: string
}

const SignUpPageContext = React.createContext<SignUpPageContextValue>({
  formValues: DEFAULT_FORM_VALUES,
  setFormValues: () => undefined,
  setLeadState: () => undefined,
  canContinue: true,
  continueToNextPage: () => undefined,
  setIsValid: () => undefined,
  setValidCouponCode: () => undefined,
  setClientSecret: () => undefined,
  setBillingFormValues: () => undefined,
  billingError: undefined,
})

export const useSignUpPageContext = (): SignUpPageContextValue =>
  useContext(SignUpPageContext)

const SignUpPage = ({ data, pageContext }: Props): JSX.Element => {
  const [canContinue, updateCanContinue] = useState<boolean>(true)
  const [isValid, setIsValid] = useState<boolean>(true)

  const signUpPage = data.signUpPage
  const firstPageSlug = data.pages.nodes[0].slug

  // preserve search params in the URL
  const navigate = useCallback(async (path: string) => {
    const url = new URL(window.location.href)
    const searchParams = url.searchParams.toString()

    await gatsbyNavigate(`${path}${searchParams ? `?${searchParams}` : ''}`)
  }, [])

  const { searchParamsLoaded, leadState, setLeadState } = useSignUpParams({
    signUpPage,
    pageContext,
    firstPageSlug,
    navigate,
  })

  // custom hooks for specific page interactions
  const {
    setValidCouponCode,
    setBillingFormValues,
    setClientSecret,
    submitBilling,
    submittingBilling,
    saveBillingError,
  } = useBillingPage({ pageContext, leadState })
  const { formValues, setFormValues, submitForm } = useSignUpForm({
    signUpPage,
    pageContext,
    canContinue,
    setLeadState,
    navigate,
  })

  const { country } = formValues

  useEffect(() => {
    if (isValid) {
      updateCanContinue(true)
    }
  }, [isValid])

  const continueClicked = useCallback(async () => {
    // handle form data submission
    if (!isValid) {
      updateCanContinue(false)
      return
    }
    if (pageContext.slug === ABOUT_YOU_SLUG) {
      try {
        await submitForm()
        // navigate to next page
        if (!supportedCountries.includes(country)) {
          // redirect to wait list page if not a supported country
          signUpPage?.modules?.forEach((module) => {
            if (
              module?.__typename === 'STRAPI__COMPONENT_SIGN_UP_SIGN_UP_FORM' &&
              module.waitListEndpoint
            ) {
              navigate(module.waitListEndpoint).catch(console.error)
            }
          })
        } else if (pageContext.nextSlug) {
          navigate(`${SIGN_UP_PREFIX}/${pageContext.nextSlug}`).catch(
            console.error,
          )
        }
      } catch (error) {
        console.error(error)
      }
    } else {
      if (pageContext.slug === BILLING_SLUG) {
        const billingSubmittedSuccessfully = await submitBilling()

        // dont proceed to next page if unsuccessful
        if (!billingSubmittedSuccessfully) {
          return
        }
      }

      if (pageContext.nextSlug) {
        // update "Next step" to "match" in Airtable if next page is 'find-my-assistant'
        if (pageContext.nextSlug === FIND_MY_ASSISTANT_SLUG) {
          markLeadReadyToBeMatched()
        }

        navigate(`${SIGN_UP_PREFIX}/${pageContext.nextSlug}`).catch(
          console.error,
        )
      }
    }
  }, [
    country,
    isValid,
    navigate,
    pageContext.nextSlug,
    pageContext.slug,
    signUpPage?.modules,
    submitBilling,
    submitForm,
  ])

  const displayFooter = Boolean(
    !signUpPage?.footer?.hideFooter &&
      signUpPage?.footer?.hasContinueButton &&
      pageContext.nextSlug,
  )

  return (
    <SignUpPageContext.Provider
      value={{
        formValues,
        setFormValues,
        setLeadState,
        canContinue,
        continueToNextPage: continueClicked,
        setIsValid,
        setValidCouponCode,
        setClientSecret,
        setBillingFormValues,
        billingError: saveBillingError?.message,
      }}
    >
      <Page
        head={{
          title: signUpPage?.title,
          description: 'Find your assistant today!',
        }}
        hideHeader={!!signUpPage?.pageOptions?.hideHeader}
        hideFooter={!!signUpPage?.pageOptions?.hideFooter}
        hideIntercom
      >
        <Outer footerFixed={!!signUpPage?.footer?.hasContinueButton}>
          {/* disable interaction with page while billing is being saved */}
          {submittingBilling && <Loader $fullscreen />}

          {signUpPage?.showStepsIndicator &&
            typeof signUpPage?.index === 'number' && (
              <StepsIndicatorWrapper currentPageIndex={signUpPage.index} />
            )}
          {/* dont load main content until search params have been read in properly */}
          {!searchParamsLoaded ? (
            <StyledLoader />
          ) : (
            <Inner>
              {signUpPage?.titleImage?.imageSrc && (
                <TitleImage
                  src={signUpPage.titleImage.imageSrc}
                  hasStepIndicator={Boolean(
                    signUpPage?.showStepsIndicator &&
                      typeof signUpPage?.index === 'number',
                  )}
                />
              )}
              {signUpPage?.title && (
                <StyledHero
                  hasTitleImage={!!signUpPage?.titleImage?.imageSrc}
                  title={signUpPage?.title}
                />
              )}
              <SignUpPageContent modules={signUpPage?.modules} />
            </Inner>
          )}
        </Outer>
        {displayFooter && (
          <SignUpFooter
            canContinue={canContinue}
            onContinueClick={continueClicked}
            calendlyLink={signUpPage?.footer?.calendlyLink || undefined}
          />
        )}
      </Page>
    </SignUpPageContext.Provider>
  )
}

// wrap sign up page to provide Stripe Element context to the whole SignUpPage component
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_API_KEY || '')
const SignUpPageWrapper = (props: Props): JSX.Element => (
  <Elements stripe={stripePromise}>
    <SignUpPage {...props} />
  </Elements>
)

export default SignUpPageWrapper
