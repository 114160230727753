import { SIGNUP_LEAD_STATE_KEY } from '../../../constants'
import { getSessionState } from '../../../hooks/useSessionState'
import type { Lead } from '../../../pages/joined'
import signup from '../../join-forms/signup'
import { trackEvent } from '../../utils'

const addCouponCodeToLead = async (
  couponCode: string,
): Promise<{ leadRecordId: string | null } | undefined> => {
  const leadState = getSessionState<Lead>(SIGNUP_LEAD_STATE_KEY)
  const leadRecordId = leadState?.value?.leadRecordId

  if (leadRecordId) {
    const result = await signup({
      leadRecordId,
      couponCode,
    })

    trackEvent('coupon_code_added_to_lead')

    return result
  }
}

export default addCouponCodeToLead
