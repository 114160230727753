import styled from 'styled-components'

import SmallInput from '../../../../../../system/inputs/SmallInput'
import { Color } from '../../../../../theme'
import { calloutMediumStyle } from '../../../../../typography'

export const CouponCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  row-gap: 16px;
`

export const CouponTitle = styled.div`
  ${calloutMediumStyle({ noResponsive: true })}
`

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
`

export const CouponInput = styled(SmallInput)`
  flex: 1;

  // don't adjust width based on content
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ValidationText = styled.p<{ isValid: boolean }>`
  ${calloutMediumStyle({ noColor: true, noResponsive: true })}

  ${({ isValid }) => `
    color: ${isValid ? Color.GREEN_5 : Color.FLAMINGO_6};
  `}
`
