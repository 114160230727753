import React from 'react'

import type { Strapi_SignUp_PricingFragment } from '../../../../../graphql-types'

import {
  BottomRow,
  BottomRowContent,
  BottomRowContentBody,
  BottomRowContentContainer,
  BottomRowContentTitle,
  BottomRowTitle,
  Outer,
  TopRow,
  TopTitle,
  VerticalDivider,
} from './styled'

const Pricing = ({
  billedTodayTitle,
  billedTodayAmount,
  futureBillingTitle,
  leftPriceTitle,
  leftPriceAmount,
  leftPriceDescription,
  rightPriceTitle,
  rightPriceAmount,
  rightPriceDescription,
}: Strapi_SignUp_PricingFragment): JSX.Element => (
  <Outer>
    <TopRow>
      <TopTitle>{billedTodayTitle}</TopTitle>
      <TopTitle>{billedTodayAmount}</TopTitle>
    </TopRow>
    <BottomRow>
      <BottomRowTitle>{futureBillingTitle}</BottomRowTitle>
      <BottomRowContentContainer>
        <BottomRowContent>
          <BottomRowContentTitle>
            <div>{leftPriceTitle}</div>
            <div>{leftPriceAmount}</div>
          </BottomRowContentTitle>
          {leftPriceDescription?.data?.leftPriceDescription && (
            <BottomRowContentBody
              markdown={leftPriceDescription.data.leftPriceDescription}
              openLinksInNewTab
            />
          )}
        </BottomRowContent>
        <VerticalDivider />
        <BottomRowContent>
          <BottomRowContentTitle>
            <div>{rightPriceTitle}</div>
            <div>{rightPriceAmount}</div>
          </BottomRowContentTitle>
          {rightPriceDescription?.data?.rightPriceDescription && (
            <BottomRowContentBody
              markdown={rightPriceDescription.data.rightPriceDescription}
              openLinksInNewTab
            />
          )}
        </BottomRowContent>
      </BottomRowContentContainer>
    </BottomRow>
  </Outer>
)

export default Pricing
