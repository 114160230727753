import { CardElement, Elements } from '@stripe/react-stripe-js'
import type { StripeCardElementChangeEvent } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useCallback, useEffect } from 'react'

import type { Strapi_SignUp_CreditCardFragment } from '../../../../../graphql-types'
import { useSignUpPageContext } from '../../../../templates/sign-up-page'

import CouponCode from './CouponCode'
import { CreditCardContainer, Divider, Inner, Outer, Title } from './styled'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_API_KEY || '')

const CreditCard = ({
  title,
  couponCode: canUseCouponCode,
  ...props
}: Strapi_SignUp_CreditCardFragment): JSX.Element => {
  const { setIsValid } = useSignUpPageContext()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setIsValid(false), []) // set isValid to false on load

  const validateCardInfo = useCallback(
    (event: StripeCardElementChangeEvent) => {
      setIsValid(event.complete)
    },
    [setIsValid],
  )

  return (
    <Outer>
      <Inner>
        <CreditCardContainer>
          <Title>{title}</Title>
          <Elements stripe={stripePromise}>
            <CardElement onChange={validateCardInfo} />
          </Elements>
        </CreditCardContainer>
        {canUseCouponCode && (
          <>
            <Divider />
            <CouponCode {...props} />
          </>
        )}
      </Inner>
    </Outer>
  )
}

export default CreditCard
