import React from 'react'

import type { Strapi_TextWithImageListFragment } from '../../../../../graphql-types'
import { BodySemibold, CalloutRegular } from '../../../typography'
import { getStrapiMediaSourceUrl } from '../helpers'

import { Image, ItemOuter, ListOuter, TextContainer } from './styled'

const TextWithImageList = ({
  textWithImageList,
}: Strapi_TextWithImageListFragment): JSX.Element | null => (
  <ListOuter>
    {textWithImageList?.filter(Boolean).map((item, index) => {
      // handle prod & local CMS instances
      const imgSrc = getStrapiMediaSourceUrl(item?.image?.url)

      return (
        <ItemOuter key={index}>
          {imgSrc && (
            <div>
              <Image src={imgSrc} />
            </div>
          )}
          <TextContainer>
            <BodySemibold>{item?.title}</BodySemibold>
            {item?.description && (
              <CalloutRegular>{item.description}</CalloutRegular>
            )}
          </TextContainer>
        </ItemOuter>
      )
    })}
  </ListOuter>
)

export default TextWithImageList
