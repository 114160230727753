import styled from 'styled-components'

import { mobile } from '../../../mixins'
import { Color } from '../../../theme'
import { CalloutRegular } from '../../../typography'

export const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 24px;

  background-color: ${Color.PURPLE_1};
  border-radius: 16px;

  img {
    max-width: 100%;
    cursor: pointer;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Subtitle = styled(CalloutRegular)`
  color: ${Color.GRAY_7};
`

export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border-radius: 52px;
  background-color: ${Color.GRAY_7};
  cursor: pointer;

  svg {
    color: ${Color.WHITE};
  }

  ${mobile(`
    width: 36px;
    height: 36px;
  `)}}
`

export const VideoOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 0 12px;
`

export const VideoContainer = styled.div`
  padding: 0 52px;

  video {
    width: 100%;
    max-width: 960px;
    border-radius: 10px;
  }

  ${mobile(`
    padding: 0 36px;
  `)}}
`
