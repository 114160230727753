import React from 'react'

import type { Strapi_HeaderFragment } from '../../../../../graphql-types'

import { Description, Outer, StyledH1, StyledH2, StyledH3 } from './styled'

const Header = ({
  title,
  headingSize,
  description,
}: Strapi_HeaderFragment): JSX.Element => {
  let HeadingElement
  switch (headingSize) {
    case 'h1':
      HeadingElement = StyledH1
      break
    case 'h3':
      HeadingElement = StyledH3
      break
    case 'h2':
    default:
      HeadingElement = StyledH2
  }

  return (
    <Outer>
      <HeadingElement>{title}</HeadingElement>
      {description?.data?.description && (
        <Description noResponsive>{description.data.description}</Description>
      )}
    </Outer>
  )
}

export default Header
