import React from 'react'

import type { Strapi_SignUp_CalendlyEmbedFragment } from '../../../../../graphql-types'
import CalendlyWrapper from '../CalendlyWrapper'

import { Inner, Outer } from './styled'

const CalendlyEmbed = ({
  calendlyLink,
}: Strapi_SignUp_CalendlyEmbedFragment): JSX.Element => {
  return (
    <Outer>
      <Inner>
        {calendlyLink && <CalendlyWrapper calendlyLink={calendlyLink} />}
      </Inner>
    </Outer>
  )
}

export default CalendlyEmbed
