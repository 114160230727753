import type { ChangeEvent } from 'react'
import React, { useCallback, useEffect, useState } from 'react'

import type { Strapi_SignUp_BillingFragment } from '../../../../../../../graphql-types'
import { SIGNUP_LEAD_STATE_KEY } from '../../../../../../constants'
import useSessionState from '../../../../../../hooks/useSessionState'
import type { Lead } from '../../../../../../pages/joined'
import ExtraSmallButtonPrimary from '../../../../../../system/buttons/ExtraSmallButtonPrimary'
import { useSignUpPageContext } from '../../../../../../templates/sign-up-page'
import { COUPON_REGEX } from '../../constants'

import {
  CouponCodeContainer,
  CouponInput,
  CouponTitle,
  InputContainer,
  ValidationText,
} from './styled'

const PromoCode = ({
  validPromoCodeMessage,
  invalidPromoCodeMessage,
}: Pick<
  Strapi_SignUp_BillingFragment,
  'validPromoCodeMessage' | 'invalidPromoCodeMessage'
>): JSX.Element => {
  const [couponCodeValue, setCouponCodeValue] = useState('')
  const [couponIsValid, setCouponIsValid] = useState(false)
  const [validationMessage, setValidationMessage] = useState('')
  const [canActivateCode, setCanActivateCode] = useState(false)
  const [leadState] = useSessionState<Partial<Lead>>(SIGNUP_LEAD_STATE_KEY)
  const { setValidCouponCode } = useSignUpPageContext()

  const validateCouponCode = useCallback(
    (newCouponCode: string) => {
      const isValidCode = COUPON_REGEX.test(newCouponCode)

      const message = isValidCode
        ? validPromoCodeMessage?.replace('{{code}}', newCouponCode) ||
          `Code ${newCouponCode} gives you $150 off your first month!`
        : invalidPromoCodeMessage || 'Sorry, that code doesn’t work!'

      setCouponIsValid(isValidCode)
      setValidationMessage(message)

      // if valid, bubble up coupon code to SignUpPage for submission
      setValidCouponCode(isValidCode ? newCouponCode : null)

      // disable Activate button after submitting code
      setCanActivateCode(false)
    },
    [invalidPromoCodeMessage, setValidCouponCode, validPromoCodeMessage],
  )

  // load coupon code from lead state
  useEffect(() => {
    if (leadState?.value?.discountCode) {
      const couponCode = leadState.value.discountCode

      setCouponCodeValue(couponCode)
      validateCouponCode(couponCode)
    }
  }, [leadState, validateCouponCode])

  const onClickActivate = useCallback(() => {
    validateCouponCode(couponCodeValue)
  }, [couponCodeValue, validateCouponCode])

  const couponCodeChanged = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setCouponCodeValue(value)

      // enable Activate button if coupon code changes and not empty
      setCanActivateCode(!!value)
    },
    [],
  )

  return (
    <CouponCodeContainer>
      <CouponTitle>{'Do you have a promo code? 🎁'}</CouponTitle>
      <InputContainer>
        <CouponInput
          placeholder={'Enter your promo code'}
          value={couponCodeValue}
          onChange={couponCodeChanged}
        />
        <ExtraSmallButtonPrimary
          disabled={!canActivateCode}
          onClick={onClickActivate}
        >
          {'Activate'}
        </ExtraSmallButtonPrimary>
      </InputContainer>
      {validationMessage && (
        <ValidationText isValid={couponIsValid}>
          {validationMessage}
        </ValidationText>
      )}
    </CouponCodeContainer>
  )
}

export default PromoCode
