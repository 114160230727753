import { getHubspotutk } from '../../hooks/useHubspotutk'
import type { Utm } from '../../hooks/useUtm'
import isMobile from '../../utils/isMobile'
import { requestGraphql } from '../api'

type SignupInput = {
  leadRecordId?: string
  email?: string
  givenName?: string
  familyName?: string
  country?: string
  phone?: string
  companyName?: string
  emailProvider?: string
  mobileOs?: string
  isTeam?: boolean
  isHighDelegator?: boolean
  isUnsure?: boolean
  painPoints?: string
  whenStart?: string
  howHeard?: string
  howHeardOther?: string
  gclid?: string
  utm?: Utm
  timeZone?: string
  seenBillingAt?: string
  submittedBillingAt?: string
  isReadyToBeMatched?: boolean
  introCallScheduled?: boolean
  couponCode?: string
}

interface Result {
  leadRecordId: string | null
}

const signup = async (input: SignupInput): Promise<undefined | Result> => {
  const data = await requestGraphql<{
    signup: {
      isSuccess: boolean
      leadRecordId: string | null
    }
  }>({
    operationName: 'Signup',
    variables: {
      input: {
        hubspotutk: getHubspotutk(),
        deviceType: isMobile ? 'mobile' : 'desktop',
        ...input,
      },
    },
    query: `
        mutation Signup($input: SignupInput!) {
          signup(input: $input) {
            isSuccess
            leadRecordId
          }
        }
      `,
  })

  return data?.signup
}

export default signup
