import styled, { css } from 'styled-components'

import MarkdownArticle from '../../../Markdown'
import { mobile } from '../../../mixins'
import { Color } from '../../../theme'
import {
  bodyMediumStyle,
  bodySemiboldStyle,
  captionRegularStyle,
} from '../../../typography'

export const Outer = styled.div`
  width: 100%;
  max-width: 640px;
  padding: 20px 24px 24px;
  margin-top: 16px !important;
  border-radius: 12px;
  background-color: ${Color.GRAY_1};

  display: flex;
  flex-direction: column;
  row-gap: 20px;
`

export const TopRow = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid ${Color.GRAY_2};
  display: flex;
  justify-content: space-between;
`

export const TopTitle = styled.div`
  ${bodySemiboldStyle({ noColor: true })};
  color: ${Color.GREEN_5};
`

export const BottomRow = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`

export const BottomRowTitle = styled.div`
  ${bodySemiboldStyle};
`

export const BottomRowContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 23px;

  ${mobile(css`
    flex-direction: column;
    row-gap: 16px;
  `)}
`

export const BottomRowContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

export const BottomRowContentTitle = styled.div`
  ${bodyMediumStyle({ noResponsive: true })};
  font-size: 15px;
  line-height: 140%;
  display: flex;
  justify-content: space-between;
`

export const BottomRowContentBody = styled(MarkdownArticle)`
  margin: 0;

  span {
    ${captionRegularStyle};
    color: ${Color.GRAY_6};
    font-size: 13px;
  }

  a {
    text-decoration: underline;
  }
`

export const VerticalDivider = styled.div`
  width: 0.5px;
  border-left: 1px solid ${Color.GRAY_2};

  ${mobile(css`
    display: none;
  `)}
`
