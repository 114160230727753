import React from 'react'

import type { Strapi_SignUp_BillingFragment } from '../../../../../../graphql-types'

import {
  DetailSection,
  Divider,
  Outer,
  SectionDescription,
  SummaryRow,
  Title,
  TitleRow,
} from './styled'

const Pricing = ({
  pricingTitle,
  pricingSections,
  summaryTitle,
  summaryPrice,
}: Pick<
  Strapi_SignUp_BillingFragment,
  'pricingTitle' | 'pricingSections' | 'summaryTitle' | 'summaryPrice'
>): JSX.Element => (
  <Outer>
    <Title>{pricingTitle || 'Billed at the end of your first month:'}</Title>
    {pricingSections?.map((section) => (
      <DetailSection key={section?.sectionTitle}>
        <TitleRow>
          <p>{section?.sectionTitle}</p>
          <p>{section?.sectionPrice}</p>
        </TitleRow>
        {section?.sectionDescription?.data?.sectionDescription && (
          <SectionDescription>
            {section.sectionDescription.data.sectionDescription}
          </SectionDescription>
        )}
      </DetailSection>
    ))}
    <Divider />
    <SummaryRow>
      <p>{summaryTitle || 'Billed today'}</p>
      <p>{summaryPrice || '$0'}</p>
    </SummaryRow>
  </Outer>
)

export default Pricing
