import React, { useEffect, useState } from 'react'

import type { Strapi_SignUp_BillingFragment } from '../../../../../graphql-types'
import { useSignUpPageContext } from '../../../../templates/sign-up-page'

import PaymentDetails from './PaymentDetails'
import Pricing from './Pricing'
import { ErrorMessage, Inner, Outer } from './styled'
import type { BillingFormValues } from './types'
import { createStripeIntent, validateBillingFormValues } from './utils'

const Billing = ({
  billingEmailInputLabel,
  showPromoCode,
  validPromoCodeMessage,
  invalidPromoCodeMessage,
  ...pricingProps
}: Omit<Strapi_SignUp_BillingFragment, '__typename'>): JSX.Element => {
  const { setIsValid, setClientSecret, setBillingFormValues, billingError } =
    useSignUpPageContext()
  const [formValuesState, setFormValuesState] = useState<BillingFormValues>()
  const [localClientSecretState, setLocalClientSecretState] = useState<string>()

  // create Stripe intent & set isValid to false when component mounts
  useEffect(() => {
    const getClientSecret = async (): Promise<void> => {
      const clientSecret = await createStripeIntent()

      if (clientSecret) {
        setClientSecret(clientSecret)
        setLocalClientSecretState(clientSecret)
      }
    }

    setIsValid(false)
    getClientSecret().catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (formValuesState) {
      setIsValid(
        Boolean(
          localClientSecretState && validateBillingFormValues(formValuesState),
        ),
      )
      setBillingFormValues(formValuesState)
    }
  }, [
    formValuesState,
    localClientSecretState,
    setBillingFormValues,
    setIsValid,
  ])

  return (
    <Outer>
      {billingError && <ErrorMessage>{billingError}</ErrorMessage>}
      <Inner>
        <PaymentDetails
          emailInputLabel={billingEmailInputLabel}
          showPromoCode={showPromoCode}
          validPromoCodeMessage={validPromoCodeMessage}
          invalidPromoCodeMessage={invalidPromoCodeMessage}
          onValuesChange={setFormValuesState}
        />
        <Pricing {...pricingProps} />
      </Inner>
    </Outer>
  )
}

export default Billing
