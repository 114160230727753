import React from 'react'

import type { Strapi_SignUp_GenericOrBlockFragment } from '../../../../../../graphql-types'

import BlockContent from './BlockContent'
import { GenericOrBlockInner, GenericOrBlockOuter, OrDivider } from './styled'

const GenericOrBlock = (
  orBlock: Strapi_SignUp_GenericOrBlockFragment,
): JSX.Element | null => {
  const blocksLength = orBlock.blocks?.length || 0
  if (!blocksLength) return null

  return (
    <GenericOrBlockOuter>
      {orBlock.blocks?.map((block, index) => {
        if (!block) return null

        return (
          <GenericOrBlockInner key={index}>
            <BlockContent {...block} />
            {index < blocksLength - 1 && (
              <OrDivider>
                <span>{'or'}</span>
              </OrDivider>
            )}
          </GenericOrBlockInner>
        )
      })}
    </GenericOrBlockOuter>
  )
}

export default GenericOrBlock
