import type { ComponentProps } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const Container = styled.div<{ $height: string }>`
  ${({ $height }) => `
    height: ${$height};
    overflow: hidden;
    transition: height 250ms ease-in-out 0s;
  `}
`

type Props = Omit<ComponentProps<typeof Container>, '$height'> & {
  isExpanded: boolean
}

const CollapsibleContainer = ({ isExpanded, ...props }: Props): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [containerHeight, setContainerHeight] = useState<string>('0px')

  useEffect(() => {
    // manually set height on expand/collapse to trigger transition animation
    if (isExpanded) {
      containerRef.current?.scrollHeight &&
        setContainerHeight(`${containerRef.current.scrollHeight}px`)
    } else {
      setContainerHeight('0px')
    }
  }, [isExpanded])

  return <Container ref={containerRef} $height={containerHeight} {...props} />
}

export default CollapsibleContainer
