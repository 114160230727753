import styled from 'styled-components'

import { maxScreenWidth } from '../../../mixins'

export const ListOuter = styled.div`
  max-width: 800px;
  margin-top: 52px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  ${maxScreenWidth(900)(`
    max-width: 640px;
  `)}
`
