import styled, { css } from 'styled-components'

import { mobile } from '../../../mixins'
import {
  CalloutRegular,
  heading1Style,
  heading2Style,
  heading3Style,
} from '../../../typography'

export const Outer = styled.div`
  display: flex;
  flex-direction: column;

  text-align: center;

  ${mobile(`
    margin: 56px 0 0;
    padding: 0 12px;
  `)}
`

const sharedHeadingStyle = css`
  width: 100%;
  max-width: 720px;
`

export const StyledH1 = styled.h1`
  ${heading1Style({ noResponsive: true })}
  ${sharedHeadingStyle}
`

export const StyledH2 = styled.h2`
  ${heading2Style({ noResponsive: true })}
  ${sharedHeadingStyle}
`

export const StyledH3 = styled.h3`
  ${heading3Style({ noResponsive: true })}
  ${sharedHeadingStyle}
`

export const Description = styled(CalloutRegular)`
  width: 100%;
  max-width: 610px;
  margin-top: 20px;

  ${mobile(`
    margin-top: 24px;
  `)}
`
