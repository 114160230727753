import styled, { css } from 'styled-components'

import SmallInput from '../../../../../system/inputs/SmallInput'
import { mobile } from '../../../../mixins'
import { Color } from '../../../../theme'
import {
  bodyMediumStyle,
  calloutMediumStyle,
  calloutRegularStyle,
  captionRegularStyle,
} from '../../../../typography'

export const Outer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding: 24px;
  border: 1px solid ${Color.GRAY_3};
  border-radius: 12px;

  ${mobile(css`
    padding: 24px 16px;
  `)}
`

export const Title = styled.div`
  ${bodyMediumStyle({ noResponsive: true })}
  margin-bottom: 16px;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  flex: 1;

  label {
    ${captionRegularStyle}
    color: ${Color.GRAY_7};
    margin-bottom: 4px;
  }
`

export const Input = styled(SmallInput)`
  width: 100%;
`

export const CardElementOuter = styled.div<{ $focused?: boolean }>`
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div {
    flex-grow: 1;
  }

  border: 1px solid ${Color.GRAY_2};
  border-radius: 10px;
  padding: 0 12px;
  // TO KEEP
  flex: 1 1 auto;

  ${({ $focused }) =>
    !!$focused &&
    css`
      border: 1px solid ${Color.PURPLE_4};
    `}
`

export const Divider = styled.hr`
  width: 100%;
  margin: 12px 0 0 0;
  border: 0;
  border-top: 1px solid ${Color.GRAY_3};
`

export const AddressOuter = styled.div`
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

export const AddressTitle = styled.div`
  ${calloutMediumStyle({ noResponsive: true })}
  margin-bottom: 16px;
`

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;

  ${mobile(css`
    flex-direction: column;
    gap: 0px;
  `)}
`

export const StateAndZipCodeOuter = styled.div`
  min-width: 0;
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 16px;

  // keep zip code container large enough to support 'Postal code' label w/o wrapping
  div:nth-of-type(2) {
    min-width: 80px;
  }
`

export const SelectOuter = styled.div`
  position: relative;

  select {
    ${calloutRegularStyle({ noResponsive: true })}
    width: 100%;
    height: 36px;
    background: ${Color.WHITE};
    border: 1px solid ${Color.GRAY_2};
    border-radius: 10px;
    padding: 0 12px;
    color: ${Color.BLACK};

    &:focus {
      border: 1px solid ${Color.PURPLE_4};
    }

    appearance: none;
  }

  svg {
    position: absolute;
    right: 8px;
    top: 6px;
    pointer-events: none;
    color: ${Color.GRAY_6};
  }
`
