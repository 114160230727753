import styled from 'styled-components'

import { maxScreenWidth } from '../../../mixins'

export const Outer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 40px;

  & > * {
    flex: 1;
  }

  ${maxScreenWidth(900)(`
    flex-direction: column;
    row-gap: 56px;
  `)}
`
