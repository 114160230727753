import React, { useMemo } from 'react'

import { SIGNUP_LEAD_STATE_KEY } from '../../../constants'
import { supportedAmericas, supportedEurope } from '../../../countries'
import useSessionState from '../../../hooks/useSessionState'
import type { Lead } from '../../../pages/joined'
import CalendlyScheduler from '../../../system/CalendlyScheduler'

type Props = {
  calendlyLink: string
}

const CalendlyWrapper = ({ calendlyLink }: Props): JSX.Element => {
  const [leadState] = useSessionState<Partial<Lead>>(SIGNUP_LEAD_STATE_KEY)

  const country = leadState?.value?.country
  const countryValue = useMemo(() => {
    if (!country) return undefined
    if (supportedAmericas.includes(country)) return 'United States/Canada'
    if (supportedEurope.includes(country)) return 'France/Central Europe'
    else return 'Other'
  }, [country])

  return (
    <CalendlyScheduler
      calendlyLink={calendlyLink}
      leadRecordId={leadState?.value?.leadRecordId}
      name={leadState?.value?.fullName}
      email={leadState?.value?.email}
      phone={leadState?.value?.phone}
      countryValue={countryValue}
    />
  )
}

export default CalendlyWrapper
