import styled from 'styled-components'

import MarkdownArticle from '../../../../Markdown'
import { maxScreenWidth } from '../../../../mixins'
import { Color } from '../../../../theme'
import {
  calloutRegularStyle,
  calloutSemiboldStyle,
} from '../../../../typography'

export const GenericBlockOuter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 20px;

  padding: 24px;
  border: 1px solid ${Color.GRAY_3};
  border-radius: 16px;

  ${maxScreenWidth(900)(`
    flex-direction: column;
  `)}
`

export const GenericOrBlockOuter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;

  border: 1px solid ${Color.GRAY_3};
  border-radius: 16px;

  ${maxScreenWidth(900)(`
    flex-direction: column;
  `)}

  & > div:not(:last-child) {
    border-color: ${Color.GRAY_3};
    border-style: solid;
    border-right-width: 1px;
    border-bottom-width: 0;

    ${maxScreenWidth(900)(`
      border-right-width: 0;
      border-bottom-width: 1px;

      padding-bottom: 48px;
    `)}
  }

  & > div:not(:first-child) {
    ${maxScreenWidth(900)(`
      padding-top: 48px;
    `)}
  }
`

export const GenericOrBlockInner = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 20px;

  padding: 24px;

  ${maxScreenWidth(900)(`
    flex-direction: column;
  `)}
`

export const OrDivider = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);

  ${maxScreenWidth(900)(`
    top: 100%;
    right: 50%;
  `)}

  span {
    ${calloutSemiboldStyle({ noResponsive: true })}
    display: block;
    height: 48px;
    width: 48px;
    line-height: 48px;

    border-radius: 24px;

    background-color: ${Color.GRAY_2};
    color: ${Color.BLACK};
    text-align: center;
  }
`

export const Image = styled.img`
  width: 72px;
`

export const ContentContainer = styled.div<{ $hasImage?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 16px;

  // center the content if there is no image, otherwise align to the left
  align-items: ${({ $hasImage }) => ($hasImage ? 'flex-start' : 'center')};
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`

export const Description = styled(MarkdownArticle)`
  margin: 0;

  span {
    ${calloutRegularStyle};
  }

  a {
    text-decoration: underline;
  }
`
