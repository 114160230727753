import type { ChangeEvent } from 'react'
import React, { useCallback, useEffect, useState } from 'react'

import type { Strapi_SignUp_CreditCardFragment } from '../../../../../../graphql-types'
import { SIGNUP_LEAD_STATE_KEY } from '../../../../../constants'
import useSessionState from '../../../../../hooks/useSessionState'
import type { Lead } from '../../../../../pages/joined'
import SmallButtonPrimary from '../../../../../system/buttons/SmallButtonPrimary'
import { useSignUpPageContext } from '../../../../../templates/sign-up-page'
import { COUPON_REGEX } from '../../Billing/constants'

import {
  CouponCodeContainer,
  CouponInput,
  CouponTitle,
  InputContainer,
  ValidationText,
} from './styled'

const CouponCode = ({
  validCouponCodeMessage,
  invalidCouponCodeMessage,
}: Pick<
  Strapi_SignUp_CreditCardFragment,
  'validCouponCodeMessage' | 'invalidCouponCodeMessage'
>): JSX.Element => {
  const [couponCodeValue, setCouponCodeValue] = useState('')
  const [couponIsValid, setCouponIsValid] = useState(false)
  const [validationMessage, setValidationMessage] = useState('')
  const [canActivateCode, setCanActivateCode] = useState(false)
  const [leadState] = useSessionState<Partial<Lead>>(SIGNUP_LEAD_STATE_KEY)
  const { setValidCouponCode } = useSignUpPageContext()

  const validateCouponCode = useCallback(
    (newCouponCode: string) => {
      const isValidCode = COUPON_REGEX.test(newCouponCode)

      const message = isValidCode
        ? (
            validCouponCodeMessage ||
            'Code {{code}} gives you $150 off your first month!'
          ).replace('{{code}}', newCouponCode)
        : invalidCouponCodeMessage || 'Sorry, that code doesn’t work!'

      setCouponIsValid(isValidCode)
      setValidationMessage(message)

      // if valid, bubble up coupon code to SignUpPage for submission
      setValidCouponCode(isValidCode ? newCouponCode : null)

      // disable Activate button after submitting code
      setCanActivateCode(false)
    },
    [invalidCouponCodeMessage, setValidCouponCode, validCouponCodeMessage],
  )

  // load coupon code from lead state
  useEffect(() => {
    if (leadState?.value?.discountCode) {
      const couponCode = leadState.value.discountCode

      setCouponCodeValue(couponCode)
      validateCouponCode(couponCode)
    }
  }, [leadState, validateCouponCode])

  const onClickActivate = useCallback(() => {
    validateCouponCode(couponCodeValue)
  }, [couponCodeValue, validateCouponCode])

  const couponCodeChanged = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setCouponCodeValue(value)

      // enable Activate button if coupon code changes and not empty
      setCanActivateCode(!!value)
    },
    [],
  )

  return (
    <CouponCodeContainer>
      <CouponTitle>{'Do you have a promo code? 🎁'}</CouponTitle>
      <InputContainer>
        <CouponInput
          placeholder={'Enter your promo code'}
          value={couponCodeValue}
          onChange={couponCodeChanged}
        />
        <SmallButtonPrimary
          disabled={!canActivateCode}
          onClick={onClickActivate}
        >
          {'Activate'}
        </SmallButtonPrimary>
      </InputContainer>
      {validationMessage && (
        <ValidationText isValid={couponIsValid}>
          {validationMessage}
        </ValidationText>
      )}
    </CouponCodeContainer>
  )
}

export default CouponCode
