import styled from 'styled-components'

import BigButtonPrimary from '../../../system/buttons/BigButtonPrimary'
import { mobile } from '../../mixins'
import { Color } from '../../theme'

export const Outer = styled.div`
  min-width: 320px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  border-top: solid 1px ${Color.GRAY_2};

  // this places the Footer over the Typeform component
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  column-gap: 20px;
  padding: 24px;

  background-color: ${Color.WHITE};

  ${mobile(`
    padding: 6px 12px;
  `)}
`

// TODO: need to talk to Alex about BigButtonPrimary vs BigButtonDark from the old kit
export const ContinueButton = styled(BigButtonPrimary)`
  padding: 0 32px;
  color: ${Color.WHITE};
  background: ${Color.PURPLE_5};

  border-radius: 16px;

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
  `}

  p {
    color: ${Color.WHITE};
  }
`
