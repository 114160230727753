/* eslint-disable  camelcase */
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { useCallback, useState } from 'react'

import { SIGNUP_LEAD_STATE_KEY } from '../../../../constants'
import { getSessionState } from '../../../../hooks/useSessionState'
import type { Lead } from '../../../../pages/joined'
import saveSignupBilling from '../../../sign-up/saveSignupBilling'

import type { SaveBillingInput } from './types'
import { mapBillingAddressToStripeAddress } from './utils'

const useSaveBilling = (): [
  (
    saveBillingInput: SaveBillingInput,
  ) => Promise<{ isSuccess: boolean } | undefined>,
  {
    loading: boolean
    error?: Error
  },
] => {
  const stripe = useStripe()
  const elements = useElements()

  const leadState = getSessionState<Lead>(SIGNUP_LEAD_STATE_KEY)
  const leadRecordId = leadState?.value?.leadRecordId

  const [isSavingState, setIsSavingState] = useState<boolean>(false)
  const [errorState, setErrorState] = useState<Error | undefined>()

  const saveBilling = useCallback(
    async ({ email, name, address, clientSecret }: SaveBillingInput) => {
      setIsSavingState(true)

      try {
        if (!leadRecordId) {
          throw new Error('leadRecordId is not defined')
        }

        if (!stripe) {
          throw new Error('Stripe is not defined')
        }

        if (!elements) {
          throw new Error('Stripe elements are not defined')
        }

        const cardElement = elements.getElement('card')
        if (!cardElement) {
          throw new Error('Stripe card element is not defined')
        }

        const { setupIntent, error: stripeSetupIntentError } =
          await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
              billing_details: {
                email,
                name,
                address: mapBillingAddressToStripeAddress(address),
              },
              card: cardElement,
            },
          })

        if (stripeSetupIntentError || !setupIntent) {
          throw new Error(
            stripeSetupIntentError
              ? stripeSetupIntentError.message
              : 'stripe.confirmCardSetup failed: setupIntent is undefined',
          )
        }

        const paymentMethodId = setupIntent.payment_method
        if (!paymentMethodId) {
          throw new Error('setupIntent.payment_method is not defined')
        }

        const result = await saveSignupBilling({
          leadRecordId,
          address: {
            ...address,
            country: address.country.code,
          },
          // casting as string because we know it won't be a type of PaymentMethod
          // (https://stripe.com/docs/api/setup_intents/object#setup_intent_object-payment_method)
          paymentMethodId: paymentMethodId as string,
        })

        return result
      } catch (error) {
        setErrorState(error as Error)
        console.error(error)

        return { isSuccess: false }
      } finally {
        setIsSavingState(false)
      }
    },
    [leadRecordId, stripe, elements],
  )

  return [
    saveBilling,
    {
      loading: isSavingState,
      error: errorState,
    },
  ]
}

export default useSaveBilling
