import StrapiModule from '..'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useMemo } from 'react'

import type { GetContentRowsQuery } from '../../../../../graphql-types'

import { Outer } from './styled'

export const query = graphql`
  query GetContentRows {
    contentRows: allStrapiContentRow {
      nodes {
        ...ContentRow
      }
    }
  }
`

type Props = {
  customStrapiId: number
}

const RowModule = ({ customStrapiId }: Props): JSX.Element => {
  const data = useStaticQuery<GetContentRowsQuery>(query)
  const contentRow = useMemo(
    () =>
      data.contentRows.nodes.find((row) => row.strapi_id === customStrapiId),
    [customStrapiId, data.contentRows.nodes],
  )

  return (
    <Outer>
      {contentRow?.content?.map(
        (module, index) =>
          !!module && (
            // need a container div to keep modules from stretching to fit the tallest module
            <div key={index}>
              <StrapiModule
                key={`${module.__typename || ''}${index}`}
                module={module}
              />
            </div>
          ),
      )}
    </Outer>
  )
}

export default RowModule
