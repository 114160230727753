import styled from 'styled-components'

import { Color } from '../../../theme'
import { bodySemiboldStyle } from '../../../typography'

export const Outer = styled.div`
  width: 100%;
  max-width: 640px;
  margin-top: 32px;
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${Color.GRAY_3};
  border-radius: 12px;
`

export const CreditCardContainer = styled.div`
  padding: 24px;

  display: flex;
  flex-direction: column;
  row-gap: 12px;
`

export const Title = styled.div`
  ${bodySemiboldStyle}
`

export const Divider = styled.div`
  width: 100%;
  border-top: 1px solid ${Color.GRAY_3};
`
