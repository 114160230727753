import { requestGraphql } from '../api'

type CreateSignupBillingSetupIntentInput = {
  leadRecordId: string
}

interface Result {
  clientSecret: string
}

const createSignupBillingSetupIntent = async (
  input: CreateSignupBillingSetupIntentInput,
): Promise<undefined | Result> => {
  const data = await requestGraphql<{
    createSignupBillingSetupIntent: Result
  }>({
    operationName: 'CreateSignupBillingSetupIntent',
    variables: {
      input,
    },
    query: `
      mutation CreateSignupBillingSetupIntent($input: CreateSignupBillingSetupIntentInput!) {
        createSignupBillingSetupIntent(input: $input) {
          clientSecret
        }
      }
    `,
  })

  return data?.createSignupBillingSetupIntent
}

export default createSignupBillingSetupIntent
