import type { Maybe } from '../../../../graphql-types'

export const getStrapiMediaSourceUrl = (
  url?: Maybe<string>,
): string | undefined =>
  url
    ? url.startsWith('https://')
      ? url
      : `${process.env.GATSBY_STRAPI_API_URL}${url}`
    : undefined
