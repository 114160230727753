import { DateTime } from 'luxon'
import { useCallback, useEffect, useState } from 'react'

import signup from '../../../components/join-forms/signup'
import type { BillingFormValues } from '../../../components/modules/strapi/Billing/types'
import useSaveBilling from '../../../components/modules/strapi/Billing/useSaveBilling'
import addCouponCodeToLead from '../../../components/modules/strapi/addCouponCodeToLead'
import type { Lead } from '../../../pages/joined'
import { BILLING_SLUG } from '../constants'

type Props = {
  pageContext: { slug?: string }
  leadState?: { value?: Partial<Lead> }
}

type ReturnProps = {
  setValidCouponCode: (value: string | null) => void
  setBillingFormValues: (value: BillingFormValues) => void
  setClientSecret: (value: string | null) => void
  submitBilling: () => Promise<boolean>
  submittingBilling: boolean
  saveBillingError: Error | undefined
}

const useBillingPage = ({ pageContext, leadState }: Props): ReturnProps => {
  const [validCouponCode, setValidCouponCode] = useState<string | null>()
  const [billingFormValues, setBillingFormValues] = useState<BillingFormValues>(
    {
      name: '',
      email: '',
      cardComplete: false,
      address: {
        country: { code: '', name: '' },
        city: '',
        line1: '',
        postalCode: '',
      },
    },
  )
  const [clientSecret, setClientSecret] = useState<string | null>()
  const [submittingBilling, setSubmittingBilling] = useState(false)
  const [
    saveBilling,
    { loading: saveBillingLoading, error: saveBillingError },
  ] = useSaveBilling()

  // update signup mutation when visiting the billing page
  useEffect(() => {
    if (pageContext.slug === BILLING_SLUG && leadState?.value?.leadRecordId) {
      signup({
        leadRecordId: leadState.value.leadRecordId,
        seenBillingAt: DateTime.local().toISO(),
      }).catch(console.error)
    }
  }, [leadState?.value?.leadRecordId, pageContext.slug])

  const submitBilling = useCallback(async (): Promise<boolean> => {
    setSubmittingBilling(true)

    // update Airtable if valid coupon code provided on billing page
    if (validCouponCode) {
      await addCouponCodeToLead(validCouponCode)
    }

    // save billing info if intent has been created
    if (clientSecret) {
      const result = await saveBilling({
        address: billingFormValues.address,
        email: billingFormValues.email,
        name: billingFormValues.name,
        clientSecret,
      })

      if (!result?.isSuccess) {
        setSubmittingBilling(false)
        return false
      } else if (leadState?.value?.leadRecordId) {
        // set submittedBillingAt value if billing saved successfully
        signup({
          leadRecordId: leadState.value.leadRecordId,
          submittedBillingAt: DateTime.local().toISO(),
        }).catch(console.error)
      }
    }

    setSubmittingBilling(false)
    return true
  }, [
    billingFormValues,
    clientSecret,
    leadState?.value?.leadRecordId,
    saveBilling,
    validCouponCode,
  ])

  return {
    setValidCouponCode,
    setBillingFormValues,
    setClientSecret,
    submitBilling,
    submittingBilling: saveBillingLoading || submittingBilling,
    saveBillingError,
  }
}

export default useBillingPage
