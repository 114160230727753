import styled from 'styled-components'

import { mobile } from '../../../mixins'

export const ListOuter = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  ${mobile(`
    row-gap: 32px;
  `)}
`

export const ItemOuter = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;

  ${mobile(`
    column-gap: 16px;
  `)}
`

export const Image = styled.img`
  width: 72px;

  ${mobile(`
    width: 56px;
  `)}
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  ${mobile(`
    row-gap: 6px;
  `)}
`
