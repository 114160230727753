import styled from 'styled-components'

import SmallInput from '../../../../../system/inputs/SmallInput'
import { Color } from '../../../../theme'
import { bodyMediumStyle } from '../../../../typography'

export const CouponCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 24px 24px;
  row-gap: 12px;
`

export const CouponTitle = styled.div`
  ${bodyMediumStyle}
`

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
`

export const CouponInput = styled(SmallInput)`
  flex: 1;
`

export const ValidationText = styled.p<{ isValid: boolean }>`
  ${bodyMediumStyle({ noColor: true })}

  ${({ isValid }) => `
    color: ${isValid ? Color.GREEN_5 : Color.FLAMINGO_6};
  `}
`
