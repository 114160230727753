import React from 'react'

import type { Strapi_SignUp_GenericBlockFragment } from '../../../../../../graphql-types'

import BlockContent from './BlockContent'
import { GenericBlockOuter } from './styled'

const GenericBlock = (
  block: Strapi_SignUp_GenericBlockFragment,
): JSX.Element => (
  <GenericBlockOuter>
    <BlockContent {...block} />
  </GenericBlockOuter>
)

export default GenericBlock
