import { useState, useEffect } from 'react'

import countries from '../countries'

type CountryCode = string
type IsLoaded = boolean

const useCountryFromNavigator = (): [CountryCode | undefined, IsLoaded] => {
  const [value, setValue] = useState<CountryCode | undefined>()
  const [loaded, setLoaded] = useState<IsLoaded>(false)

  useEffect(() => {
    // @ts-ignore
    const [, locale] = String(
      window.navigator.userLanguage || window.navigator.language || '',
    )
      .toUpperCase()
      .split('-')
    setValue(
      countries.some(({ code }: { code: CountryCode }) => code === locale)
        ? locale
        : undefined,
    )
    setLoaded(true)
  }, [setValue, setLoaded])

  return [value, loaded]
}

export default useCountryFromNavigator
