import React from 'react'

import type { Strapi_SignUp_ButtonGroupFragment } from '../../../../../graphql-types'
import Button from '../Button'
import { ACTIONS } from '../actions'
import type { ButtonType } from '../types'

import { ButtonContainer } from './styled'

const ButtonGroup = ({
  buttons,
}: Omit<Strapi_SignUp_ButtonGroupFragment, '__typename'>): JSX.Element => (
  <ButtonContainer>
    {buttons?.map((button, index) => {
      if (!button) return null

      const { label, type, navigateTo, action } = button
      const buttonAction = action ? ACTIONS[action] : undefined
      return (
        type && (
          <Button
            key={`${label}-${index}`}
            type={type as ButtonType}
            label={label || ''}
            navigateTo={navigateTo || undefined}
            action={buttonAction}
          />
        )
      )
    })}
  </ButtonContainer>
)

export default ButtonGroup
