import styled from 'styled-components'

import { mobile } from '../../../mixins'
import { Color } from '../../../theme'
import { heading2Style } from '../../../typography'

export const Outer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 48px;
  align-items: center;
  text-align: center;
  max-width: 640px;
  padding: 100px 20px;

  border: 1px solid ${Color.GRAY_3};
  border-radius: 16px;

  ${mobile(`
    padding: 56px 16px 16px;
  `)}
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`

export const Title = styled.h2`
  ${heading2Style({ noResponsive: true })}
`
