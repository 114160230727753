/* eslint-disable @typescript-eslint/ban-ts-comment */
import { navigate } from 'gatsby'
import React, { useCallback, useState } from 'react'

import type {
  Strapi_SignUp_LeadClassificationFormFragment,
  Strapi_SignUp_LeadClassificationOptionFragment,
} from '../../../../../graphql-types'
import { useSignUpPageContext } from '../../../../templates/sign-up-page'
import { getStrapiMediaSourceUrl } from '../helpers'

import OptionButton from './OptionButton'
import { Outer } from './styled'

const LeadClassificationForm = ({
  options,
}: Strapi_SignUp_LeadClassificationFormFragment): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState<number>(-1)
  const { setFormValues, continueToNextPage, setLeadState } =
    useSignUpPageContext()

  const onOptionClick = useCallback(
    (
      index: number,
      option: Strapi_SignUp_LeadClassificationOptionFragment | null,
    ) => {
      if (!option) return
      setSelectedOption(index)
      const classificationFormValues = {
        isHighDelegator: !!option.indicatesHighDelegator,
        isTeam: !!option.indicatesTeam,
        isUnsure: !!option.isUnsure,
      }
      setLeadState(classificationFormValues, false)
      setFormValues(classificationFormValues)
      if (option.onClickURL) return navigate(option.onClickURL)
      // design request to delay 600ms before redirecting
      setTimeout(() => {
        continueToNextPage()
      }, 600)
    },
    [continueToNextPage, setFormValues, setLeadState],
  )

  if (!options) return <></>

  return (
    <Outer>
      {options.map((option, index) => (
        <OptionButton
          key={index}
          icon={getStrapiMediaSourceUrl(option?.image?.url) || ''}
          boldTitle={option?.boldText || ''}
          remainingTitle={option?.regularText || ''}
          onClick={() => onOptionClick(index, option)}
          isSelected={selectedOption === index}
        />
      ))}
    </Outer>
  )
}

export default LeadClassificationForm
